import React, { useContext, useEffect, useState } from 'react';
import { createTheme, styled } from '@mui/material/styles';
import { ViewAllButton } from '../ViewAllButton';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { AcademicCardBox, AcademicCardCount, AcademicCardText, AcademicCardTitle, FACardBox, FACardCount, FACardText, FACardTitle,  ProcessingCardWrapper, TableHeader, TuitionCardBox, TuitionCardCount, TuitionCardText, TuitionCardTitle } from './ProcessingStyles';
import { useToken } from '../../useToken';
import SelectedSchoolContext from '../SelectedSchoolContext';
import axios from 'axios';
import { SchoolRequirement, StudentRequirement } from '../../globalTypes/objects';
import { PieChart } from '@mui/x-charts';
import { HighlightItemData } from '@mui/x-charts/context';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';


const ProcessingCard = () => {

    const [faStudentCount, setFAStudentCount] = useState<number>(0);
    const [academicStudentCount, setAcademicStudentCount] = useState<number>(0);
    const [tuitionStudentCount, setTuitionStudentCount] = useState<number>(0);

    const pieParams = { height: 108, margin: { right: 5 }, width: 250 };
    const palette = ['#7F96FF', 'rgba(186, 153, 118, 1)', '#253872'];
    const pieData = [
        { id: 0, value: faStudentCount, label: 'Financial Aid' },
        { id: 1, value: academicStudentCount, label: 'Academic' },
        { id: 2, value: tuitionStudentCount, label: 'Tuition' },]

    const [highlightedItem, setHighLightedItem] =
        React.useState<HighlightItemData | null>(null);


    const getToken = useToken();
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
  /*  const { isLoadingSchools, selectedSchoolId, selectedSchoolName } = contextValue;*/
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
       if (selectedSchoolId != null) { 
            loadData();
        } 
    }, [selectedSchoolId]);


    const loadData = async () => {
        try {
            const localToken = await getToken();
          
            const { data } = await axios.get<SchoolRequirement>
                (`${API_BASE_URL}/api/Processing/getStudentRequirementsCounts/${selectedSchoolId}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    });
            setFAStudentCount(data.faNeedCount);
            setTuitionStudentCount(data.tuitionNeedCount);
            setAcademicStudentCount(data.academicNeedCount);
               
           
} catch ({ message }) {
    console.log('An unknown error occurred' || message);
        }
    };
    return (
        <>
            <div className="dashboardHeader">Processing</div>
            <div className="dashboardBody">
                <TableContainer sx={{ height: '340px' }}  >
                    <Table sx={{ width: '92%', marginLeft: '22px'}}>
                        <TableBody><TableRow>
                            <TableCell style={{ borderRight: '1px solid rgba(37, 56, 114, 0.14)', borderBottom: '1px solid rgba(37, 56, 114, 0.14)'}}>
                                <ProcessingCardWrapper>
                                    <FACardTitle>Financial Aid</FACardTitle>
                                    <FACardBox>
                                        <FACardCount>{faStudentCount}</FACardCount>
                                        <FACardText>{faStudentCount ==1 ? 'Student': 'Students'} with needs</FACardText>                                   
                                    </FACardBox>
                                </ProcessingCardWrapper>
                            </TableCell>
                            <TableCell style={{ borderBottom: '1px solid rgba(37, 56, 114, 0.14)' }}>
                                 <ProcessingCardWrapper>
                                    <AcademicCardTitle>Academic</AcademicCardTitle>
                                    <AcademicCardBox>
                                        <AcademicCardCount>{academicStudentCount}</AcademicCardCount>
                                        <AcademicCardText>{academicStudentCount == 1 ? 'Student' : 'Students'} with needs</AcademicCardText>
                                    </AcademicCardBox>
                                </ProcessingCardWrapper>                

                            </TableCell>
                        </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px solid rgba(37, 56, 114, 0.14)', borderBottom: '0px', paddingBottom:0 }}>
                                    <ProcessingCardWrapper>
                                        <TuitionCardTitle>Tuition</TuitionCardTitle>
                                        <TuitionCardBox>
                                            <TuitionCardCount>{tuitionStudentCount}</TuitionCardCount>
                                            <TuitionCardText>{tuitionStudentCount == 1 ? 'Student' : 'Students'} with needs</TuitionCardText>
                                        </TuitionCardBox>
                                    </ProcessingCardWrapper>
                                </TableCell>
                                <TableCell style={{ borderBottom: '0px ', paddingTop: 0, paddingBottom:0 }}>
                                    <PieChart sx={{paddingTop:0}}
                                        colors={palette}
                                        series={[{ data: pieData, innerRadius: 20, cx: 55, cy: 50, highlightScope: { highlighted: 'item', faded: 'global' } }]}
                                        {...pieParams} slotProps={{
                                            legend: {
                                                labelStyle: {
                                                    fontSize: 12,
                                                    fill: '#253872',
                                                    fontFamily: "Inter"
                                                },
                                                itemMarkWidth: 5,
                                                itemMarkHeight: 5,

                                            },
                                            popper: {
                                                sx:{
                                                    fontFamily: "Inter",
                                                    fontSize: 12,
                                                }
                                            }
                                        }}

                                        highlightedItem={highlightedItem}
                                        onHighlightChange={setHighLightedItem}
                                    
                                      
                                    />
                                 </TableCell>
                            </TableRow></TableBody>
                    </Table><ViewAllButton navigateTo="/processing" />
                </TableContainer>


             
            </div>
        </>)

};

export default ProcessingCard;