import styled from "@emotion/styled";


export const FilterButton = styled.div`
display:flex;
 border-Radius: 40px;
 background-color: white;
 border: 1px #253872 solid ;
 color:#253872;
 font-size:13px;
 padding-left:10px;
 padding-right:3px;
 padding-top:3px;
 padding-bottom:3px;
 cursor:pointer;
 font-weight:700;
`
export const FilterButtonText = styled.div`

 padding-top:3px;

`
export const FilterText = styled.span`
font-family: "Open Sans";
 color:#253872;
padding-top: 8px;
padding-right: 15px; 
`

export const HeaderCell = styled.div`
color: var(--Final-Dark-Blue, #253872);
font-family: "Open Sans";
font-size: 16px;
font-weight: 700;
height:50px;
padding-left:8px;
align-content:center;
background-color: rgba(131, 151, 248, 0.05);
border-radius:4px;
 width:170px;
`;

export const BodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

min-height:20px;
font-family: "Open Sans";
width:170px;
`;

export const DocumentBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
padding:2px;
padding-left:8px;
padding-right:1px;

font-family: "Open Sans";
display:flex;
width:170px;
`;

export const OverdueStatusBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-weight:700;
font-family: "Open Sans";
background-color: #FB4A4A;
color: #fff;
cursor:pointer;
width:170px;
`;


export const CompleteStatusBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-weight:700;
width:170px;
font-family: "Open Sans";
background-color: #72E889;
color: #fff;
text-align: center;
cursor:pointer;
`;

export const IncompleteStatusBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
justify-content: center;
 align-items: center;
 height: 50px;
font-weight:700;
width:170px;
font-family: "Open Sans";
background-color: #FFBB38;
color: #fff;
text-align: center;
cursor:pointer;
z-index:1;
`;

export const ToDoStatusBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-weight:700;
width:170px;
font-family: "Open Sans";
background-color: #BA9976 ;
color: #fff;
text-align: center;
cursor:pointer;
`;

export const UploadedStatusBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
font-weight:700;
font-family: "Open Sans";
background-color: #B1B1B1;
color: #fff;
text-align: center;
cursor:pointer;
width:170px;
`;

export const OverdueProjectBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
border-left: 4px solid #FB4A4A;
display: flex;
  align-items: center;
  height: 50px;
font-family: "Open Sans";
width:180px;
padding-left:5px;

`;

export const CompleteProjectBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
border-left: 4px solid #72E889;
display: flex;
  align-items: center;
  height: 50px;
 
padding-left:5px;
font-family: "Open Sans";
width:180px;
`;

export const IncompleteProjectBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
border-left: 4px solid #FFBB38;
display: flex;
  align-items: center;
  height: 50px;
font-family: "Open Sans";
width:180px;

padding-left:5px;
`;

export const ToDoProjectBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
border-left: 4px solid  #BA9976;
display: flex;
  align-items: center;
  height: 50px;
  
padding-left:5px;
font-family: "Open Sans";
width:180px;
`;

export const UploadedProjectBodyCell = styled.div`
border-radius: 4px;
border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.10));
border-left: 4px solid #B1B1B1;
display: flex;
  align-items: center;
  height: 50px;
  
padding-left:5px;
font-family: "Open Sans";
width:180px;
`;

export const CounterFrame = styled.div`
    align-items: center;
    background-color: #253872;
    border-radius: 80px;
    display: flex;
    gap: 8px;
    height: 14px;
    justify-content: center;
    padding: 8px;
    position: relative;
    width: 16px;
    margin-left:6px;
`;

export const CounterText = styled.div`
    color: var(--white);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -6.5px;
    margin-left: -2.5px;
    margin-right: -2.5px;
    margin-top: -6.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
`;


export const IncompleteComment = styled.div`
border-radius: 10px 10px 10px 10px;
background-color:  rgba(131, 151, 248, 0.07);
max-height: 100px;
max-height: 80px;
margin-left:10px;
padding: 8px;
margin-bottom: 18px;
width:400px;
overflow-y:auto;
overflow-x:hidden;
text-align:left;
`;

export const CommentInfo = styled.div`
color:#7F96FF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
  text-align: left;
  padding-bottom:10px;
  padding-left:12px;
`;
export const CommentDate = styled.div`
color:#253872;
font-size: 12px;
font-family: Inter;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 180px;
  padding-bottom:10px;
  padding-top:8px;
`;


export const CommentHeader = styled.div`
padding-bottom:15px;  
 text-align: left;
 border-bottom: 1px solid rgba(37, 56, 114, 0.14);
 font-weight:700;
color:#253872;
font-size:14px;

 `;

export const GarbageImg = styled.img`
padding-left: 10px;
  padding-bottom:10px;
  padding-top:5px;

 `;