import React, { useState, useEffect } from 'react';
import UploadFile from './UploadFile';
import { useToken } from '../useToken';
import axios from 'axios';
import { API_BASE_URL } from '../urlConfig';

const TestUpload = (uploadPath: string) => {
    const [success, setSuccess] = useState(false);
    const [names, setNames] = useState<string[]>([]);
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getstudents = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Test/gettest`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setNames(data);
        } catch (error: any) {
            console.log(error);
            //setError(error.message || 'An unknown error occurred');
        }
    };

    useEffect(() => {
        getstudents();
    }, []);
    return (
        <div>
            <div style={{width: '80%'}}>
                <h1>Test Upload </h1>
                <div>lets see if this shows up</div>
              <div>First names:</div>
                {success && <h2>File uploaded successfully</h2>}
                {names.map((name) =>
                    <div>{name}</div>
                )}
            </div>

        </div>

    );
};

export default TestUpload;