import React, { useState } from 'react';
import styled from '@emotion/styled';
import commentIcon from '../../Images/commentIcon.svg';
import redIcon from '../../Images/redCircleIcon.svg';




type Props = {
    wasNotRead: boolean;
}


const Wrapper = styled.div`
cursor:pointer;
display:flex;
`;
const Icon = styled.img`

overflow: hidden;
height: 40px;
margin-top: 8px;

`;

const RedCircle = styled.img`

z-index:3;
margin-left: -6px;
margin-top: -15px;

`;
const UploadText = styled.div`
color: #7f96ff;
font-size:12px;
margin-right: 2px;
`;

const UploadIcon = ({ wasNotRead }: Props) => {

    return (
        <Wrapper>
            <Icon src={commentIcon} alt="Comment Icon" />
               {wasNotRead && <RedCircle src={redIcon}></RedCircle>}
         
        </Wrapper>
    );
};
export default UploadIcon;
