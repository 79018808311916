import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IAnnouncementState {
    announcementId: number;
}


const initialState: IAnnouncementState = {
    announcementId: 0,
}

export const announcementSlice = createSlice({
    name: "announcement",
    initialState,
    reducers: {
        setAnnouncementState: (state, action: PayloadAction<number>) => {
            state.announcementId = action.payload;
        },
    },
});

export const { setAnnouncementState } = announcementSlice.actions;
export const announcementReducer = announcementSlice.reducer;


