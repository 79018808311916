import React from 'react';

const Help = () => {
    return (<>

        <div className="pageHeader">Help</div>
        <br /> <br />
        <div>
          <div>Commonly Asked Questions:</div>
        </div> </>
    );
};
export default Help;
