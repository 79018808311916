import React from 'react';
import { ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import AddFeedback from './AddFeedback';
import { CloseButton } from '../CloseButton';

type Props = {
    onClose: () => void;
}

const FeedbackModal = ({ onClose }: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Feedback</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
             
                    <AddFeedback   />
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default FeedbackModal;
