import React, { useState } from 'react';
import styled from '@emotion/styled';
import nextIcon from '../../Images/NextIcon.svg';

type Props = {
    onClick: () => void;
}

const Wrapper = styled.div`
cursor:pointer;
display:flex`;



const Icon = styled.img`
width: 30px;

overflow: hidden;
height: 30px;
margin-top: 8px;
margin-left: 6px;

`;

const IconText = styled.div`

margin-top: 12px;
margin-left: 6px;

`;
const NextIcon =  ({ onClick }: Props) => {

    return (
        <Wrapper onClick={onClick}>
       <IconText>Next</IconText> 
            <Icon src={nextIcon} alt="Next" />

        </Wrapper>
    );
};
export default NextIcon;
