import React, { useState } from 'react';
import axios from 'axios';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { useToken } from "../../useToken";
import { CloseButton } from '../CloseButton';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    id: number;
    projectDescription: string;
    onClose: () => void;
}

const EditStatus = ({ id, projectDescription, onClose }: Props) => {
    const [editStatus, setEditStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState('');
    const [note, setNote] = useState('');
    const [invalidNote, setInvalidNote] = useState(false);
  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleStatusChange = (status: string) => {
        setEditStatus(status);
    };
    const handleNoteChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        setNote(inputValue);

        if (inputValue.length >= 350) {
            setInvalidNote(true);
        } else {
            setInvalidNote(false);
        }
    };
    const getToken = useToken();
    const handleSaveStatus = async () => {
        if (editStatus !== '') {
            const localToken = await getToken();
            setLoading(true);

            axios.post(`${API_BASE_URL}/api/timeline/updateStatus`, { status: editStatus, id, incompleteNote: note }, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
                .then(() => {
                    console.log(response);
                    setSuccess(true);
                    setLoading(false);
                    onClose();
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    setLoading(false);
                    onClose();
                })
        }
    };

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Edit Status</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                    <div>
                        <div style={{ width: '100%', paddingTop:'35px' }}>

                            <div className="modal">
                                <strong>
                                    {projectDescription} Status:
                                </strong>
                                <div className="modal-content">
                            <br />
                                <div>
                                 <label>
                                        <input
                                            type="radio"
                                            value="Complete"
                                            checked={editStatus === 'Complete'}
                                            onChange={() => handleStatusChange('Complete')}
                                        />
                                        Complete
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Incomplete"
                                            checked={editStatus === 'Incomplete'}
                                            onChange={() => handleStatusChange('Incomplete')}
                                        />
                                        Incomplete
                                    </label>
                                    {/*<label>*/}
                                    {/*    <input*/}
                                    {/*        type="radio"*/}
                                    {/*        value="Overdue"*/}
                                    {/*        checked={editStatus === 'Overdue'}*/}
                                    {/*        onChange={() => handleStatusChange('Overdue')}*/}
                                    {/*    />*/}
                                    {/*    Overdue*/}
                                    {/*</label>*/}

                                    </div>
                                    {editStatus === 'Incomplete' && <div style={{ paddingTop: '50px' }}>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Add Comment Here"
                                            multiline rows={3}
                                            onChange={handleNoteChange} fullWidth
                                        />
                                    </div>}
                              
                                    <br /><br />
                         
                                    <Button onClick={() => {
                                        handleSaveStatus().then(() => {
                                            onClose()
                                        })

                                    }} variant="outlined" style={{ color: '#253872' }}>Save
                                    </Button>
                                {response && (
                                    <div>
                                        <p style={response == "Success" ? { color: 'green' } : { color: 'red' }}>Response: {response}</p>
                                    </div>
                                    )}
                                    {invalidNote && (
                                        <div>
                                            <p style={{ color: 'red' }}>Comment is too long</p>
                                        </div>
                                    )}
                            </div>
                        </div>

                    </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>


    );
};

export default EditStatus;