//import { configureStore } from '@reduxjs/toolkit';
//import { bookSlice } from './bookSlice';

//export const store = configureStore({
//    reducer: {
//        book: bookSlice.reducer,
//    },
//});

//export type RootState = ReturnType<typeof store.getState>;
//export type AppDispatch = typeof store.dispatch;


import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { persistReducer, persistStore, PURGE } from "redux-persist";
import { schoolReducer } from "./schoolSlice";
import { announcementReducer } from "./announcementSlice";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
    return {
        getItem() {
            return Promise.resolve(null);
        },
        setItem(_key: string, value: number) {
            return Promise.resolve(value);
        },
        removeItem() {
            return Promise.resolve();
        },
    };
};




const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const schoolPersistConfig = {
    key: "school",
    storage: storage,
    whitelist: ["school"],
};

const announcementPersistConfig = {
    key: "announcement",
    storage: storage,
    whitelist: ["announcementId"],
};


const persistedReducer = persistReducer(schoolPersistConfig, schoolReducer);
const persistedAnnouncementReducer = persistReducer(announcementPersistConfig, announcementReducer);

const rootReducer = combineReducers({
    school: persistedReducer,
    announcement: persistedAnnouncementReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
