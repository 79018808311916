import React, { useEffect, useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import UploadFile from '../UploadFile';
import { Need, StudentRequirement } from '../../globalTypes/objects';
import StudentData from './StudentData';
import NeedItem from './NeedItem';
import Button from '@mui/material/Button';
import { CloseButton } from '../CloseButton';
import UploadFileProcessing from './UploadFileProcessing';

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number|null;
    needs: Need[];
    onClose: () => void;
}


const StudentUploadMultipleModal = ({ onClose, studentName, studentId, schoolName, schoolId, needs }: Props) => {
    const [success, setSuccess] = useState(false);
    const [selectedNeeds, setSelectedNeeds] = useState<number[]>([]);
    const [needsCategory, setNeedsCategory] = useState<string>();
   
    const handleCheckboxChange = (id: number) => {
        if (selectedNeeds.includes(id)) {
            setSelectedNeeds(selectedNeeds.filter((needId) => needId !== id));
        } else {
            setSelectedNeeds([...selectedNeeds, id]);
        }     
    };
    useEffect(() => {
        if (needs.length == 1) {//default checkbox to checked when there is only 1 need.
            handleCheckboxChange(needs[0].id);
        }
        setNeedsCategory(needs[0].type);
    }, []);


    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{studentName} #{studentId} Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                {success ?
                    (<div style={{ color: '#5EB234' }}>File Successfully Uploaded!</div>)
                    :
                    (<div>
                        {/*<StudentData*/}
                        {/*    studentName={studentName}*/}
                        {/*/>*/}

                            <div style={{ textAlign: 'left' }}>
                                {needs.map((need) => (
                                    <div key={need.id} style={{ display: 'flex', padding:'5px' }}>
                                    <input
                                        type="checkbox"
                                        id={`need-${need.id}`}
                                        checked={selectedNeeds.includes(need.id)}
                                        onChange={() => handleCheckboxChange(need.id)}
                                    />
                                        <label style={{ paddingLeft: '10px', paddingRight: '15px', width: '100%' }} htmlFor={`need-${need.id}`}><NeedItem need={need} /></label>
                                </div>
                            ))}
                            </div>
                           


                            <br /><br />
                        <UploadFileProcessing
                                uploadPath={`api/document/uploadStudentNeedDocument/${studentId}/${schoolId}?${selectedNeeds.map((id) => `needIds=${id}`).join('&')}`}
                                onSuccess={() => setSuccess(true)}
                                acceptedFileTypes={{
                                    'application/pdf': ['.pdf'],
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                                    'text/plain': ['.txt'],
                                }}
                                selectedNeeds={selectedNeeds}
                               
                            />
                        </div>)
                    }    
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default StudentUploadMultipleModal;
