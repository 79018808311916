import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { School } from "../types";

export interface ISchoolState {
    school: School;
    //  schoolId: number;
}


const initialState: ISchoolState = {
    school: { id: 0, name: '' },
    // schoolId:0,
}

export const schoolSlice = createSlice({
    name: "school",
    initialState,
    reducers: {
        setSchoolState: (state, action: PayloadAction<School>) => {
            state.school = action.payload;
            //  state.schoolId = ac
        },
    },
});

export const { setSchoolState } = schoolSlice.actions;
export const schoolReducer = schoolSlice.reducer;