import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RxDashboard } from "react-icons/rx";
import { TfiAnnouncement } from "react-icons/tfi";
import { VscServerProcess } from "react-icons/vsc";
import { MdOutlineTimeline, MdHistory, MdCalendarMonth } from "react-icons/md";
import { TbFileReport } from "react-icons/tb";
import { RiAdminLine } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import '../styles.css';
import logo from '../Images/hecmLogo.svg';
import dashboardIcon from '../Images/dashboardIcon.svg';
import announcementIcon from '../Images/announcementIcon.svg';
import processingIcon from '../Images/processingIcon.svg';
import projectIcon from '../Images/projectIcon.svg';
import calendarIcon from '../Images/calanderDetailedIcon.svg';
import helpIcon from '../Images/helpBrownIcon.svg';
import feedbackIcon from '../Images/feedbackBrownIcon.svg';
import studentFolderIcon from '../Images/studentFolderIcon.svg';
/*import PermissionsContext from './PermissionsContext';*/
import "@fontsource/open-sans";
import { useToken } from '../useToken';
import FeedbackModal from './Feedback/FeedbackModal';

const Wrapper = styled.div`
  background-color:white ;
  color: #253872 ;
  position: fixed;
  left: 0;
  top:0;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 250px;
  z-index: 500;
  border-radius: 0px 5px;
  @media (max-width:800px){
        width: 60px;
    }
  
`;

const Logo = styled.div`
  background-color:white ;
 color: #253872 ;
  padding: 24px;  
  display: flex;
  flex-direction: column;
    @media (max-width:800px){
        display: none;
    }
  
`;
const SideBar = styled.div`
  background-color:white ;
 color: #253872 ;
  padding-left: 0px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;  
      @media (max-width:800px){
        padding-right: 0px;
    }
`;

const MenuOption = styled.span`
 
   padding: 14px;
    display:flex;
   align-items:center;
   border-radius: 0px 6px 6px 0px;
   :hover{
                    background-color: #F9FAFF;
                    text-decoration: auto;
                    border-radius: '0px 6px 6px 0px';
                    margin-top: 16;
                    font-weight: 700;
                    font-family: Open Sans;
                    color: #7F96FF; 
                    display: flex;
  
}
`;
const MenuBorder = styled.div`
 width: 6px;
 height: 57px; 
 background-color: #7F96FF;
 border-bottom-right-radius: 4px; 
 border-top-right-radius: 4px
`;


const IconBar = styled.div`
display: flex;

bottom: 0;
margin-left: 70px;
margin-top:100%;
    @media (max-width:800px){
        display: inline;
        margin-left: 0px;
    }
  
`;
const HelpImg = styled.img`
margin-left: 15px;
    @media (max-width:800px){
        margin-top: 10px;
        margin-left: 0px;
    }
  
`;

const Sidebar = () => {

    const { logout } = useAuth0();
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('');
    const [roles, setRoles] = useState<string[] | string>();
    const getToken = useToken();
    //const userPermissionsContext = useContext(PermissionsContext);

    //if (!userPermissionsContext) {
    //    throw new Error("UserPermissionsContext is not provided");
    //}

    //const { permissions, isPermissionsLoading } = userPermissionsContext;
    //const [isAuthorized, setIsAuthorized] = useState(false);
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);

    };
    const onFeedbackModalClose =  () => {
        setOpenFeedbackModal(false);        
    }

    useEffect(() => {
        setUserRoles();
    }, [activeTab]);
    return (
        <>
            {openFeedbackModal  && <FeedbackModal onClose={onFeedbackModalClose} />}
    
        <Wrapper>

            <Logo> <NavLink to={"/home"} style={{ textDecoration: 'auto', marginBottom: 24, paddingTop: 0 }}>
                <img width="200" src={logo} alt="Logo" />
            </NavLink>
            </Logo>
            <SideBar>
                <NavLink className="navLink" to={"/home"}
                    style={({ isActive }) => isActive ? {
                        backgroundColor: '#F9FAFF',
                        fontWeight: 700,
                        color: '#7F96FF'
                    } : {}} onClick={() => setActiveTab('dashboard')}
                > <MenuBorder style={activeTab == "dashboard" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>
                    <MenuOption>
                        <img width="27" height="27" src={dashboardIcon} style={{ marginRight: 8 }} alt="" />  <span className="MenuText">Dashboard</span>
                    </MenuOption>
                </NavLink>

                <NavLink className="navLink" to={"/announcements"}
                    style={({ isActive }) => isActive ? {
                        backgroundColor: '#F9FAFF',
                        fontWeight: 700,
                        color: '#7F96FF'
                    } : {}} onClick={() => setActiveTab('announcements')}
                >
                    <MenuBorder style={activeTab == "announcements" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>
                    <MenuOption style={{ padding: '8px' }}> <img width="40" height="40" src={announcementIcon} style={{ marginRight: 2 }} alt="" /><span className="MenuText">Announcements</span></MenuOption></NavLink>

                <NavLink to={"/processing"} className="navLink"
                    style={({ isActive }) => isActive ? {
                        backgroundColor: '#F9FAFF',
                        fontWeight: 700,
                        color: '#7F96FF'
                    } : {}} onClick={() => setActiveTab('processing')}
                >
                    <MenuBorder style={activeTab == "processing" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>

                    <MenuOption><img width="27" height="27" src={processingIcon} style={{ marginRight: 8 }} alt="" />
                        <span className="MenuText">Processing</span></MenuOption></NavLink>

                <NavLink to={"/projects"} className="navLink"
                    style={({ isActive }) => isActive ? {
                        backgroundColor: '#F9FAFF',
                        fontWeight: 700,
                        color: '#7F96FF'
                    } : {}} onClick={() => setActiveTab('projects')}
                >
                    <MenuBorder style={activeTab == "projects" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>
                    <MenuOption><img width="27" height="27" src={projectIcon} style={{ marginRight: 8 }} alt="" /> <span className="MenuText">Projects and Reports</span></MenuOption>
                    {/*<MenuOption>*/}
                    {/*    <MdOutlineTimeline*/}
                    {/*        style={{ marginRight: 8 }} /> <span className="MenuText">Timeline</span>*/}
                    {/*</MenuOption>*/}

                </NavLink>

                <NavLink to={"/yearlyoverview"} className="navLink"
                    style={({ isActive }) => isActive ? {
                        backgroundColor: '#F9FAFF',
                        fontWeight: 700,
                        color: '#7F96FF'
                    } : {}} onClick={() => setActiveTab('yearlyoverview')}
                >
                    <MenuBorder style={activeTab == "yearlyoverview" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>
                    <MenuOption><img width="27" height="27" src={calendarIcon} style={{ marginRight: 8 }} alt="" />
                        <span className="MenuText">Yearly Overview</span></MenuOption></NavLink>

                    <NavLink to={"/folders"} className="navLink"
                        style={({ isActive }) => isActive ? {
                            backgroundColor: '#F9FAFF',
                            fontWeight: 700,
                            color: '#7F96FF'
                        } : {}} onClick={() => setActiveTab('folders')}
                    >
                        <MenuBorder style={activeTab == "folders" ? { backgroundColor: '#7F96FF' } : { backgroundColor: '#fff' }} ></MenuBorder>
                        <MenuOption><img width="31" height="29" src={studentFolderIcon} style={{ marginRight: 8 }} alt="" />
                            <span className="MenuText">Student Files</span></MenuOption></NavLink>


                    <IconBar>
                        <img style={{ cursor: 'pointer' }} width="30" height="30" title="Feedback" src={ feedbackIcon } onClick={() => (setOpenFeedbackModal(true))}></img>
                        <NavLink to='/help' style={{ textDecoration:'auto' }}> <HelpImg width="30" height="30" title="Help"  src={helpIcon} ></HelpImg></NavLink> 
                </IconBar>

            </SideBar>
        </Wrapper>
        </>

    )
};

export default Sidebar;