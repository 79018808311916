import React, { useEffect, useState } from 'react';
import { Paper,  TableContainer, Table, CircularProgress, TableBody, styled, TableRow, TableCell } from '@mui/material';
import { SearchBar } from '../SearchBar';
import { useToken } from '../../useToken';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    //  border: 0
    color: 'black',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(131, 151, 248, 0.07)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const formatedDate = date.format("MMMM DD");
    return formatedDate;

};

const Feedback = () => {
    const getToken = useToken();
    const [searchValue, setSearchValue] = useState<string>("");
  //  const [feedbackList, setFeedbackList] = useState<AnnouncementItem[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const getFeedback = async () => {
    try {
        const localToken = await getToken();
        const { data } = await axios.get(`${API_BASE_URL}/api/Feedback/getFeedback`, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        });
        //setFeedbackList(data);
        setLoading(false);
        setError('');
    } catch (error: any) {
        //todo
        setError(error.message);
        console.log(error.message);
        setLoading(false);
    }
    };

    useEffect(() => {
        getFeedback().catch(error => {
            console.error('Error getting Feedback:', error);
        });
    }, []);


    return (
       
            <div>  <div className="pageHeader">Feedback</div>
            {/*  <div>   <SearchBar placeholder={'Search announcements'} searchValue={searchValue}></SearchBar></div>*/}


            {/*{searchValue}*/}
            <TableContainer sx={{
                width: '85%', maxWidth: '1400px', minHeight: '100px', overflow: 'auto', padding: '0px', marginTop: '38px', borderRadius: '20px', background: 'white', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)'
            }} component={Paper}>
                <Table>
                    <>    {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '45%' }} />}

                        {/*<TableBody sx={{ padding: 0 }}>*/}
                        {/*    {feedbackList &&*/}
                        {/*        feedbackList.map(feedback => (*/}
                        {/*            <StyledTableRow key={announcement.announcementId}>*/}
                        {/*                <StyledTableCell >*/}
                        {/*                    */}{/*<Link to='/announcementviewer' target="_blank" onClick={() => { openAnnouncement(announcement.announcementId); }}*/}
                        {/*                    */}{/*    style={{ color: '#000', cursor: 'pointer', textDecoration: 'auto' }}>*/}
                        {/*                    */}{/*    {announcement.wasOpened ? announcement.announcementText : <strong>{announcement.announcementText}</strong>}*/}
                        {/*                    */}{/*</Link>*/}

                        {/*                </StyledTableCell>*/}
                        {/*                <StyledTableCell sx={{ color: '#7F96FF ', width: '100px' }}> {formatDate(announcement.dateCreated)}</StyledTableCell>*/}
                        {/*            </StyledTableRow>*/}
                        {/*        ))}*/}
                        {/*</TableBody>*/}
                    </>
                </Table>
            </TableContainer>


            

        </div>

    );
};

export default Feedback;

