import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { TimelineItem } from '../../globalTypes/objects';
import EditStatus from './EditStatus';
import { useToken } from "../../useToken";
import SchoolUploadIcon from './SchoolUploadIcon';
import SchoolProcessing from '../Processing/SchoolProcessing';
import SelectedSchoolContext from '../SelectedSchoolContext';
import SchoolDownloadIcon from './SchoolDownloadIcon';
import { NoteButton } from '../Processing/ProcessingStyles';
import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FilterButton, HeaderCell, BodyCell, OverdueStatusBodyCell, DocumentBodyCell, FilterText, FilterButtonText } from './ProjectsStyles';
import ProjectItem from './ProjectCell';
import StatusCell from './StatusCell';
import { MdEdit } from 'react-icons/md';
import filterIcon from '../../Images/filterIcon.svg';
import moment from 'moment';
import FilterCounter from './FilterCounter';
import { ViewAllButton } from '../ViewAllButton';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    minWidth: 150,
    verticalAlign: 'center',
    padding:'10px'

}));


const ProjectsReportsCard = () => {
    const [timeline, setTimeline] = useState<TimelineItem[]>();
    const [filteredData, setFilteredData] = useState<TimelineItem[]>();
    moment.locale('en-us');

    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId, selectedSchoolName } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getTimeline = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getTimelineYearlyOverview/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            const initialLoadData = data?.filter((item: { status: string; }) => item.status.toLowerCase() != 'complete').sort((item: { hesDeadline: Date; }) => item.hesDeadline).slice(0, 3);
            setTimeline(initialLoadData);
           

        } catch (error: any) {
            //todo
            console.log(error.message);
        }
    };







    const formatDate = (dateString: string) => {
        const date = moment(dateString);
        let formatedDate = date.format("MMMM DD, YYYY");
        formatedDate = formatedDate == 'Invalid date' ? '' : formatedDate;
        return formatedDate;

    };


    useEffect(() => {
        if (selectedSchoolId) {
            getTimeline().catch(error => {
            console.error('Error getting timeline:', error);
        }); } 
    }, [selectedSchoolId]);


        return (
         <>
            <div className="dashboardHeader">Projects and Reports</div>
                <div className="dashboardBody">

                    <TableContainer sx={{ height: '325px' }} >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell> <HeaderCell style={{ width: '180px' }}>Project/Report</HeaderCell></StyledTableCell>
                                 <StyledTableCell ><HeaderCell>Deadline</HeaderCell></StyledTableCell>
                                 <StyledTableCell ><HeaderCell>Status</HeaderCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {timeline &&
                                    timeline.map(x => (
                                        <TableRow
                                            key={x.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell component="th" scope="row" >
                                                <ProjectItem projectDescription={x.taskDescription} status={x.status} />
                                            </StyledTableCell>
                                    
                                            <StyledTableCell ><BodyCell>{formatDate(x.deadlineDate)}</BodyCell></StyledTableCell>
                                        
                                            <StyledTableCell>
                                                <StatusCell status={x.status} projectId={x.id} projectNotes={x.timelineNotes} projectDescription={x.taskDescription} onStatusClick={() => ("")} isCard={true} />
                                            </StyledTableCell>

                                        </TableRow>
                                    ))}
                                
                            </TableBody>
                        </Table>  <ViewAllButton navigateTo="/projects" />
                    </TableContainer>
                  
                </div>
        </>
        )  
};

export default ProjectsReportsCard;