import React from 'react';
import Sidebar from "./Sidebar";
import styled from '@emotion/styled';
import {useAuth0} from "@auth0/auth0-react";
import Loader from "./Loader";
import "@fontsource/open-sans";
import Topbar from './Topbar';

const Container = styled.div`

  height: 100%;
  position: relative;
  width: 100%;
  font-family: Open Sans;
  color: #253872;

`;
const MainContentWrap = styled.div`
  display: inline-block;
  padding-left: 290px;
  padding-top: 25px;
  width: calc(100% - 290px);
  min-height: 100vh;
  background-color:rgba(131, 151, 248, 0.07);
    @media (max-width:800px){
 padding-left: 150px;
 width: calc(100% - 150px);
         }
             @media (max-width:600px){
 padding-left: 70px;
 width: calc(100% - 70px);
         }
     
`;
/* width: calc(100% - 278px); /*width: calc(100% - 290px);*//* */
interface Props {
    children: any;
}


const DefaultLayout = (props: Props) => {
    const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();
    if (!isAuthenticated && !isLoading) {
        // Redirect to the login page
        loginWithRedirect();
        return null; // Return null to prevent rendering anything in the meantime
    }

    if (isLoading) {
        return <Loader/>;
    }
    return (
        <Container>
            {isAuthenticated && <Sidebar />} {/* Conditionally render Sidebar based on authentication */}
            {isAuthenticated && <Topbar />}
            <MainContentWrap> {props.children}</MainContentWrap>
        </Container>
    );
}

// // const params = useParams();
//     return (
//         <Container>
//             <Sidebar />
//             <MainContentWrap>{props.children}</MainContentWrap>
//         </Container>
//     )
// }

export default DefaultLayout;