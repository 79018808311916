import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import UploadFile from '../UploadFile';
import axios from 'axios';
import { School } from '../../globalTypes/objects';
import DisabledDropzone from '../DisabledDropzone';
import RequiredColumns from './RequiredColumns';
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import ProjectReportSelector from '../Projects/ProjectReportSelector';
import NotAuthorized from '../NotAuthorized';
import { useToken } from '../../useToken';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { Accept } from 'react-dropzone';
import { useAppSelector } from '../../store';
import moment from 'moment';
import { API_BASE_URL } from '../../urlConfig';


const dropDownStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 255,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 56,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};



const Admin = () => {

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;

    const [selectedImport, setSelectedImport] = useState<string>();
    const [selectedProjectId, setProjectId] = useState<number | null>(0);
    const [selectedHesDueDate, setHesDueDate] = useState(moment());
    const [error, setError] = useState('');
    const [successClearNeeds, setSuccessClearNeeds] = useState('');
    const [successfulUpload, setSuccessfulUpload] = useState(false);
    const [roles, setRoles] = useState<string[] | string>();
    const [startDate, setStartDate] = useState(new Date());
    const getToken = useToken();
    const importOptions = [
        { value: "Announcement", label: "Announcement" },
        { value: "Project", label: "Projects and Reports" },
        { value: "School", label: "Schools" },
        /*    { value: "SchoolNeed", label: "School Needs" },*/
        { value: "Student", label: "Students" },
        { value: "StudentNeed", label: "Student Needs" },
        { value: "Timeline", label: "Yearly Overview" }

    ]
    const processorImportOptions = [
        { value: "Project", label: "Projects and Reports" },
        { value: "StudentNeed", label: "Student Needs" },


    ]
    const [acceptedFileTypes, setAcceptedFilesTypes] = useState<Accept>();
    const [announcementText, setAnnouncementText] = useState<string>('');
    const [uploadUrl, setUploadUrl] = useState<string>('');
  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;



    const clearNeeds = async () => {
        if(confirm("Are you sure you want to clear ALL the current needs for " + schoolState.name + "?")){     
               const localToken = await getToken();
                await axios.post(`${API_BASE_URL}/api/Admin/ClearAllNeeds/${schoolState.id}`, {},
                    {
                        headers:
                        {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    }).then(() => {
                       setSuccessClearNeeds('Successfully cleared all needs.');
                    })
                    .catch(error => {
                        setError(error.message || 'An unknown error occurred while clearing needs.');
                    });
        }
     };
    function onSelectImportChange(e: any) {
        const importType = e?.value;
        setSelectedImport(importType),
            setProjectId(0)

    }
    const setUploadSettings = () => {

        switch (selectedImport) {
            case 'Announcement': {
                const announcementImportAccept: Accept = {
                    'application/pdf': ['.pdf'],
                    //'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    //'image/jpeg': ['.jpg', '.jpeg'],
                    //'image/png': ['.png']
                };

                setAcceptedFilesTypes(announcementImportAccept);
                setUploadUrl(`api/admin/import${selectedImport}Data/${selectedSchoolId}/${announcementText}`);
                break;
            }
            case 'Project': {
                const projectImportAccept: Accept = {
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/pdf': ['.pdf'],
                    'text/plain': ['.txt'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                };
                setAcceptedFilesTypes(projectImportAccept);
                const hesDueDateString = selectedHesDueDate.format('MM-DD-yyyy');
                setUploadUrl(`api/document/uploadProjectDocument/${selectedSchoolId}/${selectedProjectId}/${hesDueDateString}`);
                break;
            }
            default: {
                const allImportsAccept: Accept = {
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.ms-excel': ['.xls'],

                };

                setAcceptedFilesTypes(allImportsAccept);
                setUploadUrl(`api/admin/import${selectedImport}Data/${selectedSchoolId}`);
                break;
            }

        }
    }

    const setUserRoles = async () => {
        const localToken = await getToken();
        setRoles(localToken?.roles);
   
    };
    useEffect(() => {
        setUserRoles();
        setUploadSettings();
    }, [selectedImport, selectedProjectId,selectedHesDueDate, announcementText]);

    if (roles?.includes('SuperAdmin') || roles?.includes('Admin') || roles?.includes('Processor')) {
        return (
            <>

                {!selectedSchoolId && <Loader />}

                {selectedSchoolId && (<div style={{ width: '1200px' }}>
                    <div className="pageHeader">Imports</div>
                    <br /><br />
                    <div style={{ display: 'flex', gap: '24px' }}>

                        <Select
                            options={
                                roles?.includes('SuperAdmin') || roles?.includes('Admin') ?
                                    importOptions?.map(s => ({
                                        label: s.label,
                                        value: s.value,
                                    })) :
                                    processorImportOptions?.map(s => ({
                                        label: s.label,
                                        value: s.value,
                                    }))
                            }
                            onChange={(e) => onSelectImportChange(e)}
                            styles={dropDownStyles}
                            placeholder="Select Import Type"
                            isClearable={true}
                        />

                    </div>

                    {selectedImport && selectedImport != "Project" && <RequiredColumns importType={selectedImport} />}
                    {selectedImport && selectedImport == "Project" && <ProjectReportSelector projectId={selectedProjectId} onSelectedProjectChange={setProjectId} onHESDueDateChange={setHesDueDate} />}


                    {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                    {/*    <DemoContainer components={['DatePicker']}>*/}
                    {/*        <DatePicker label="Hes Due Date" />*/}
                    {/*    </DemoContainer>*/}
                    {/*</LocalizationProvider>*/}
                    <div style={{ marginTop: 12, width: '100%' }}>

                        {((selectedSchoolId && selectedImport) && selectedImport == "Announcement") &&
                            <TextField fullWidth style={{ paddingBottom: '15px' }} id="outlined-basic" placeholder="Announcement Text" variant="outlined" onChange={(e) => setAnnouncementText(e.target.value)} />}
                        {(selectedSchoolId && selectedImport || selectedImport == 'School') ?
                            <UploadFile
                                uploadPath={uploadUrl}
                                onSuccess={() => setSuccessfulUpload(true)}
                                acceptedFileTypes={acceptedFileTypes ?? { '': [''] }}
                            />
                            :
                            <DisabledDropzone toolTipMessage={'Please select school and import type to upload'} />
                        }
                    </div>

                    {successfulUpload &&
                        <div style={{ color: '#5EB234', margin: '8px' }}>
                            File Successfully Uploaded!
                        </div>
                    }

                </div>)}

                {selectedImport && selectedImport == "StudentNeed" && <Button style={{ marginTop: '15px', backgroundColor: '#253872' }} variant='contained' onClick={() => { clearNeeds() }}>Clear Needs</Button>}
                {successClearNeeds &&
                    <div style={{ color: '#72E889', margin: '8px' }}>
                        {successClearNeeds}
                    </div>
                }
                {error &&
                    <div style={{ color: '#FB4A4A', margin: '8px' }}>
                        {error}
                    </div>
                }

            </>
        );
    }
    else {
        return (<NotAuthorized />);
    }
};

export default Admin;
