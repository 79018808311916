import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { API_BASE_URL } from '../urlConfig';

const dropzoneStyles: React.CSSProperties = {
    border: '2px dashed #ccc', // Border color
    borderRadius: '4px', // Border radius
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f8f8f8', // Background color
    color: '#888', // Text color
    cursor: 'not-allowed', // Cursor style
    opacity: 0.6, // Opacity
};

const Tooltip = styled.div`
  position: relative;

  &:hover:after {
    content: attr(data-tooltip); 
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
  }
`;

type Props = {
    toolTipMessage: string;
}

const DisabledDropzone = ({ toolTipMessage }: Props) => {

    //const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const { getRootProps, getInputProps } = useDropzone({ disabled: true });

    return (

        < div>

            < div>
                <Tooltip data-tooltip={toolTipMessage}>
                    < div {...getRootProps()}
                        style={dropzoneStyles}>

                        <input {...getInputProps()} />

                        <p>
                            Drag and drop the files here or click to select files
                        </p>
                    </div>
                </Tooltip>
            </div>
        </div>

    );
};

export default DisabledDropzone;
