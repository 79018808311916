import React, { useState } from 'react';
import { CloseButton, ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import UploadFile from '../UploadFile';
import { Need, StudentRequirement } from '../../globalTypes/objects';
import StudentData from './StudentData';
import SchoolData from "./SchoolData";

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number|null;
    need: Need;
    onClose: () => void;
}


const StudentUploadModal = ({ onClose, studentName, studentId, schoolId, need }: Props) => {
    const [success, setSuccess] = useState(false);

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{studentName} Need Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                {success ?
                    (<div style={{ color: '#5EB234' }}>File Successfully Uploaded!</div>)
                    :
                    (<div>
                        {/*<StudentData*/}
                        {/*    studentName={studentName}*/}
                        {/*    need={need.need}*/}
                        {/*/>*/}
                        <UploadFile
                            uploadPath={`api/document/uploadStudentNeedDocument/${studentId}/${schoolId}?needIds=${need.id}`}
                            onSuccess={() => setSuccess(true)}
                            acceptedFileTypes={{
                                'application/pdf': ['.pdf'],
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                                'text/plain': ['.txt'],
                            }} />
                    </div>)
                    }</ModalBody>

            </ModalContent>
        </ModalOverlay>
    );
};

export default StudentUploadModal;
