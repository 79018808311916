import React, { useState } from 'react';
import styled from '@emotion/styled';
import previousIcon from '../../Images/PreviousIcon.svg';

type Props = {
    onClick: () => void;
}

const Wrapper = styled.div`
cursor:pointer`;


const Icon = styled.img`
width: 30px;

overflow: hidden;
height: 30px;
margin-top: 8px;
margin-left: 6px;

`;

const PreviousIcon = ({ onClick }: Props) => {

    return (
        <Wrapper onClick={onClick}>

            <Icon src={previousIcon} alt="Next"  />

        </Wrapper>
    );
};
export default PreviousIcon;
