import React, { useState,useRef } from 'react';
import styled from '@emotion/styled';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import axios from 'axios';
import saveAs from 'file-saver';
import { useToken } from '../../useToken';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint } from "react-to-print";
import printJS from 'print-js';

type Props = {
    blob?: Blob;
   }


const Wrapper = styled.div`
cursor:pointer;
margin-left:25px;
`;



const Icon = styled.img`
width: 40px;
max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 10px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:12px; 
margin:5px;

`; const Elipse = styled.div`
width:40px;
border-radius: 50%;
background-color: rgba(131, 151, 248, 0.05);
margin-top: 8px;
height: 40px;`;



const AnnouncementPrintIcon = ({ blob }: Props) => {


    const handleFormPrint = async () => {
        try {
            if (blob != null) {      
                const uri = URL.createObjectURL(blob);
                printJS({ printable: uri});
            }
            
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    return (<Wrapper onClick={() => handleFormPrint()}>
        <Elipse>   <MdPrint color={'rgb(127, 150, 255)'} fontSize={'20'} style={{ marginTop: '10px', marginLeft:'9px' }} />  </Elipse>
            <UploadText>Print</UploadText></Wrapper>
        
    );
};
export default AnnouncementPrintIcon;
