import React, {useState} from 'react';
import RouterComponent from './Router'; 
import SelectedSchoolContext from './components/SelectedSchoolContext';
//import PermissionsContext from './components/PermissionsContext';


//const SelectedSchoolContext = React.createContext<SchoolContextType | null>(null);

const App: React.FC = () => {
    //const [isLoadingSchools, setIsLoadingSchools] = useState<boolean>(true); // [isLoadingSchools, setIsLoadingSchools
    //const [selectedSchoolId, setSelectedSchoolId] = useState<number | null>(null);
    //const [selectedSchoolName, setSelectedSchoolName] = useState<string | null>(null);
    //const [isPermissionsLoading, setIsPermissionsLoading] = useState<boolean>(true); // [isLoadingSchools, setIsLoadingSchools
    //const [permissions, setPermissions ] = useState<string[]>([]);

    return (
        
        <React.StrictMode>
           {/* <PermissionsContext.Provider value={{ permissions, isPermissionsLoading, setPermissions,setIsPermissionsLoading }}>*/}
           {/* <SelectedSchoolContext.Provider value={{ isLoadingSchools, setIsLoadingSchools,  selectedSchoolId, setSelectedSchoolId ,selectedSchoolName,setSelectedSchoolName}}>*/}
                   <RouterComponent />
               
          {/*  </SelectedSchoolContext.Provider>*/}
           {/* </PermissionsContext.Provider>*/}
        </React.StrictMode>
    );
}

export default App;
/*
Module not found: Error: You attempted to import ../Router which falls outside of the project src/ directory. Relative imports outside of src/ are not supported.
    You can either move it inside src/, or add a symlink to it from project's node_modules/.*/
