import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>The page you are looking for doesn't exist on Hecm Client Portal</p>
        </div>
    );
};
export default NotFoundPage;
