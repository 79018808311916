import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import SelectedSchoolContext from '../SelectedSchoolContext';
import axios from "axios";
import { TimelineItem } from '../../globalTypes/objects';
import { useToken } from '../../useToken';
import Select from "react-select";
import { useAppSelector } from '../../store';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
/*import dayjs, { Dayjs } from 'dayjs';*/
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import { DateField } from '@mui/x-date-pickers/DateField';
import moment from 'moment';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    projectId?: number|null;
    onSelectedProjectChange?: (newType: number | null) => void;
    onHESDueDateChange?: (newType: any) => void;
}
//const taskId = this.state;

const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 255,backgroundColor:'white'
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 56,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};

/*{ projectId }: Props*/
const ProjectReportSelector = ({ projectId, onSelectedProjectChange, onHESDueDateChange }: Props) => {
    const [projects, setProjects] = useState<TimelineItem[]>();
    const [selectedProject, setSelectedProject] = useState<number>();
   const [hesDueDate, setHesDueDate] = React.useState( moment());
  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    moment.locale('en-us');

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;

    const getProjects = async () => {
        try {
            const localToken = await getToken();
            if (selectedSchoolId) {
                const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getProjects/${selectedSchoolId}`, {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                });
                setProjects(data); 
            }

        } catch (error: any) {
            //todo
        }
    };
    //const locales = ['en-us', 'en-gb', 'zh-cn', 'de'];
    //type LocaleKey = (typeof locales)[number];
    //const [locale, setLocale] = React.useState<LocaleKey>('en-us');

    //if (moment.locale() !== locale) {
    //    moment.locale(locale);
    //}

    useEffect(() => {
        getProjects();
    }, []);


    //function handleClick() {
    //    setProjectId((s) => {

    //        setProjectId(s?.value)
    //        onChange && onChange(newValue);

    //        return s?.value;
    //    })
    //}
    //const handleValueChange = e => {
    //    setSelectedProject(e.target.value);
    //    props.getValue(e.target.value);
    //} 
 
return (
        <div style={{ width: '1200px', marginTop: "15px", display: 'flex' }}>


            {projects &&         
                <Select
                options={projects?.map(s => ({
                    label: s.taskDescription +" (" +s.deadlineMonth+")",
                    value: s.id,
                }))}
                onChange={(event) => onSelectedProjectChange?.(event? event.value:null)}
                styles={customStyles}
                placeholder="Select Project or Report"
               />
            }
            <div style={{ marginLeft: "15px"}} >
                <LocalizationProvider dateAdapter={AdapterMoment}  >
                    <DatePicker label="Hes Due Date" sx={{ backgroundColor: '#fff', borderColor: '#e5e5ea' }}
                     value={hesDueDate} onChange={(newValue) => onHESDueDateChange?.(newValue ?? moment())} />
        
            </LocalizationProvider></div>
        </div>

    );
};

export default ProjectReportSelector;

