import React from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import AddNote from './AddNote';
import { Need, StudentRequirement } from '../../globalTypes/objects';
import StudentData from './StudentData';
import { CloseButton } from '../CloseButton';

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number;
    needs: Need[];
   // setNeed: React.Dispatch<React.SetStateAction<Need | null>>
    onClose: () => void;
}

const NoteModal = ({ onClose, studentName, studentId, schoolName, schoolId, needs }: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{studentName} #{studentId} Comment</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                    {/*<StudentData*/}
                    {/*    studentName={studentName}                */}
                    {/*/>*/}
                    <AddNote studentName={studentName}
                        studentId={studentId}
                        schoolName={schoolName}
                        schoolId={schoolId}
                        needs={needs}
                         />
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default NoteModal;
