import React from 'react';
import '../styles.css';
import { NavLink } from 'react-router-dom';


type Props = {
    navigateTo: string;
}

export const ViewAllButton = ({ navigateTo }: Props) => {
    return (
        <NavLink to={navigateTo}>
        <div className="viewAllButton" >
            <div className ="textWrapper">View All
            </div>
            </div>
        </NavLink>
    );
};