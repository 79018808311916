import React from 'react';
import styled from '@emotion/styled';
import '../../styles.css';
import AnnouncementsTable from './AnnouncementsTable';
import { Paper, Table, TableContainer } from '@mui/material';
import { ViewAllButton } from '../ViewAllButton';

const Wrapper = styled.div`
   border-radius: 20px;
   padding: 24px;
   box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
   width: 380px;
   text-align:center;
   min-height: 325px;
   background-color: #fff;
   margin: 30px;
    @media (max-width:1000px){
     width: 250px;
         }
`;


const AnnouncementsCard = () => {

    return (
        <>
            <div className="dashboardHeader">Announcements</div>
            <div className="dashboardBody">
                <TableContainer sx={{
                    width: '100%', maxHeight: '265px', minHeight: '100px', overflowY: 'auto', padding: '0px', marginBottom: '10px', background: 'white'
                }}>
                    <Table>
                        <AnnouncementsTable />
                    </Table>
                </TableContainer>
                <ViewAllButton navigateTo="/announcements" />
            </div>
        </>
    )


};

export default AnnouncementsCard;