import React, { useState } from 'react';
import styled from '@emotion/styled';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import axios from 'axios';
import saveAs from 'file-saver';
import { useToken } from '../../useToken';
import { API_BASE_URL } from '../../urlConfig';


type Props = {
    announcementId: number;
    blob?: Blob;
   }


const Wrapper = styled.div`
cursor:pointer;
margin-left:70%`;



const Icon = styled.img`
width: 40px;
max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 10px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:12px; 

`;



const AnnouncementDownloadIcon = ({ blob, announcementId }: Props) => {


    const getToken = useToken();

    const handleFormDownload = async () => {
        try {
            logDownloadAnnouncement();
            if (blob != null) {            
                saveAs(blob, 'PortalAnnouncement.pdf');
            }
            
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logDownloadAnnouncement = async () => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadAnnouncementLog/${announcementId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };
    return (
        <Wrapper onClick={() => handleFormDownload()}>  <Icon src={downloadIcon} alt="Download Icon" />
            <UploadText>Download</UploadText></Wrapper>
    );
};
export default AnnouncementDownloadIcon;
