import React from 'react';
import { ImSpinner2 } from 'react-icons/im';
import '../styles.css';

const Loader = () => {
    return (<div>
        <ImSpinner2 className="icon-spin"/> Loading
        </div>
    );
};
export default Loader;