import React, { useState } from 'react';
import { CounterFrame, CounterText } from './ProjectsStyles';



type Props = {
    count: number;
    selectedStatus: string;
    filterName: string;
}

const FilterCounter = ({ count, selectedStatus, filterName }: Props) => {

    return (
        <CounterFrame style={selectedStatus == filterName ? { backgroundColor: '#fff' } : { backgroundColor: '#253872' }}>
            <CounterText style={selectedStatus == filterName ?  { color: '#253872' } :{ color: '#fff' }}>{count}</CounterText>
        </CounterFrame>
        );
 
};
export default FilterCounter;


