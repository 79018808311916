import React, { useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import UploadFile from '../UploadFile';
import { StudentRequirement, SchoolRequirement } from '../../globalTypes/objects';
import SchoolData from '../Processing/SchoolData';
import { CloseButton } from '../CloseButton';

type Props = {
    projectDescription: string;
    projectId: number;
    schoolId: number|null;
    onClose: () => void;
}


const SchoolUploadModal = ({ onClose, projectDescription, projectId,schoolId }: Props) => {
    const [success, setSuccess] = useState(false);

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Projects and Reports Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                <br />
                {success ?
                    (<div style={{ color: '#5EB234' }}>File Successfully Uploaded!</div>)
                    :
                    (<div>
                        <SchoolData projectDescription={projectDescription} projectId={projectId} />
                        <UploadFile
                            uploadPath={`api/document/UploadSchoolProjectDocument/${schoolId}/${projectId}`}
                           // uploadPath={`api/document/uploadSchoolNeedDocument/${requirementId}`}
                            onSuccess={() => setSuccess(true)}
                            acceptedFileTypes={{
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                'application/vnd.ms-excel': ['.xls'],
                                'application/pdf': ['.pdf'],
                                'text/plain': ['.txt'],
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                            }} />
                    </div>)
                    }
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default SchoolUploadModal;
