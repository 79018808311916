import React, { useState } from 'react';
import { CompleteProjectBodyCell, IncompleteProjectBodyCell, OverdueProjectBodyCell, ToDoProjectBodyCell, UploadedProjectBodyCell } from './ProjectsStyles';



type Props = {
    projectDescription: string;
    status: string;

}

const ProjectCell = ({ projectDescription, status }: Props) => {
    if (status == 'Complete') {


        return (
            <CompleteProjectBodyCell>
                {projectDescription}
            </CompleteProjectBodyCell>
        );
    }
    else if (status == 'Incomplete') {


        return (
            <IncompleteProjectBodyCell>
                {projectDescription}
            </IncompleteProjectBodyCell>
        );
    }
    else if (status == 'Overdue') {


        return (
            <OverdueProjectBodyCell>
                {projectDescription}
            </OverdueProjectBodyCell>
        );
    }
    else if (status == 'Uploaded') {


        return (
            <UploadedProjectBodyCell>
                {projectDescription}
            </UploadedProjectBodyCell>
        );
    }
    else {//ToDo need type
        return (
            <ToDoProjectBodyCell>
                {projectDescription}
            </ToDoProjectBodyCell>
        );
    }
};
export default ProjectCell;
