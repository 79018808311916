import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UploadedNeed } from './ProcessingStyles';
import { Need } from '../../globalTypes/objects';
import checkIcon from '../../Images/greenCheckIcon.svg';
import moment from 'moment';


const FAWrapper = styled.div`
border-radius:4px;
border-left:5px solid #7F96FF;
padding:6px;
padding-left:15px;
background-color: rgba(73, 93, 219, 0.12);
  align-items: center;
  display:flex;

`;

const TuitionWrapper = styled.div`
border-radius:4px;
border-left:5px solid #253872;
padding:6px;
padding-left:15px;
background-color: rgba(37, 56, 114, 0.08);
  align-items: center;
  display:flex;
`;

const AcademicWrapper = styled.div`
border-radius:4px;
border-left:5px solid #BA9976;
padding:6px;
padding-left:15px;
background-color: rgba(186, 153, 118, 0.13);
  align-items: center;
  display:flex;
`;

type Props = {
    need: Need;

}


const NeedItem = ({ need, }: Props) => {
    moment.locale('en-us');
const formatDate = (dateString: string) => {
    const date = moment.utc(dateString);
    const formatedDate = date.format("MMM DD");
    return formatedDate;

};

    if (need.type == 'FA') {


        return (
            <FAWrapper>
                <div style={{ width: '98%' }} > <span style={{ color: '#7F96FF', marginRight:'5px' }}>{formatDate(need.needDate)} </span>{need.need}</div>
                {need.uploaded && <img title={"Completed!"} src={checkIcon} alt="Check Icon" />}
            </FAWrapper>
        );
    }
    else if (need.type == 'Academic') {
        return (
            <AcademicWrapper>
                <div style={{ width: '98%' }} ><span style={{ color: '#BA9976', marginRight: '5px' }}>{formatDate(need.needDate)} </span> {need.need}</div>
                {need.uploaded && <img title={"Completed!"} src={checkIcon} alt="Check Icon" />}

            </AcademicWrapper>
        );
    }
    else {//tuition need type
        return (
            <TuitionWrapper>
                <div style={{ width: '98%' }} > <span style={{ color: '#253872', marginRight: '5px' }}>{formatDate(need.needDate)} </span>{need.need}</div>
                {need.uploaded && <img title={"Completed!"} src={checkIcon} alt="Check Icon" />}

            </TuitionWrapper>
        );
    }
};
export default NeedItem;
