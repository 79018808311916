import styled from "@emotion/styled";
import UploadIcon from '../UploadIcon';

export const ModalOverlay = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:5;


`;

export const ModalHeader= styled.div`
background-color:rgba(131, 151, 248, 0.15);
padding-top:15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
      border-radius: 10px;
      font-weight:700;
color:#253872;
font-size:16px;
height:40px;
  @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;
export const ModalContent = styled.div`
  background: #fff;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
      border-radius: 10px;
      @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
       
 `;
export const ModalBody = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
  max-width: 600px;
  min-height: 200px;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     max-height:500px;
       overflow-y:auto;
       @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;

export const CloseButton = styled.button`
 position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
  outline: none;
  color: #000;
`;

export const colors = {
    Done: '#F1F1E6',
    notDone: '#E8A530',
    highPriority: '#ffadad',
    midPriority: '#ffd6a5',
    lowPriority: '#caffbf',
    cellBackground: '#F1F1E6',
    borderColor: '#ffffff'
};

export const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 325,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};

export const ProcessingButton = styled.button`
 background-color: ${(props) => (props.disabled ? "#b3b3b3" : "#6f8198")};
  width: 200px;
  height: 48px;
  outline: none;
  border-color: transparent;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fff;
  padding: 4px;
  
  margin-left:
  &:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

export const ProcessingButtonWrapper = styled.div`

 margin-left: 70%;

`

export const NoteButton = styled.button`
 background-color: ${(props) => (props.disabled ? "#b3b3b3" : "#6f8198")};
  width: 200px;
  height: 48px;
  outline: none;
  border-color: transparent;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fff;
  padding: 4px;
  

  &:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

export const NoteButtonWrapper = styled.div`
/* margin-top: 26px;*/
 margin-left: 16px;
`

export const Wrapper = styled.div`
  padding: 20px; 
  padding-top: 10px; 
  margin-bottom: 10px;
  border: solid 1px #2538721a;
  border-radius: 4px
  overflow-y: auto;
  background-color: #ffffff;
  width: 1200px;

`;

export const ProcessingNeed = styled.div`
display: flex;
height: 25px;
gap: 4px;

  background-color: rgba(131, 151, 248, 0.07);
 
  outline: 1px solid #2538721a;
  border-color:transparent;
  border-radius: 4px;

  color:#253872;
  padding: 4px;
`;

export const Uploaded = styled.div`
  padding: 4px;
  color: #5EB234;
  background-color: #ffffff;
`;

export const UploadedNeed = styled.span`

  color: #2538721a;
`;
export const HeaderRow = styled.div`
   display: flex;
  padding: 5px 0;
  color: #333;
  background-color: #ffffff;
`;

export const TaskColumnHeader = styled.div`
    flex: 1;
  text-align: center;
  font-weight: bold;
`;

export const CombinedTaskRow = {
    display: 'flex',
    backgroundColor: '#fff',
};

export const TaskRow = {
    ...CombinedTaskRow,
    borderTop: '5px solid #fff',
};

export const Cell = styled.div`
 flex: 1;
  padding: 20px 10px;
  text-align: center;
  background-color: ${colors.cellBackground};
  &:not(:last-child) {
    border-right: 5px solid ${colors.borderColor};
  }
`;

const StatusColumn = styled(Cell) <{ status: string }>`
  background-color: ${({ status }) => status === 'Required' ? colors.notDone : colors.Done};
`;

export const StudentHistoryContainer = styled.div`
  margin: 20px;
`;

export const StudentHistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

export const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

export const SchoolHistoryContainer = styled.div`
  margin: 20px;
`;

export const SchoolHistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

export const ProcessingCardWrapper = styled.div`
padding:5px;
padding-left:15px;
padding-right:15px;
`;

export const FACardTitle = styled.div`
 color: var(--Blue, #7F96FF);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom:5px;
`;

export const FACardBox= styled.div`
border-radius: 4px;
background: var(--Light-Blue-Background, rgba(131, 151, 248, 0.07));
border-top:4px solid  var(--Blue, #7F96FF);
display:flex;
height:80px;
width:200px;
`;

export const FACardCount = styled.span`
color: var(--Blue, #7F96FF);
font-family: "Open Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-left:30px;
padding-top:5px;
`;

export const FACardText = styled.span`
color: var(--Blue, #7F96FF);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px;
padding-top:30px;
padding-left:15px;
padding-right:30px;
`;



export const AcademicCardTitle = styled.div`
 color: rgba(186, 153, 118, 1);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom:5px;
`;

export const AcademicCardBox = styled.div`
border-radius: 4px;
background-color: var(--Light-brown, rgba(186, 153, 118, 0.13));
border-top:4px solid  rgba(186, 153, 118, 1);
display:flex;
height:80px;
width:200px;
`;

export const AcademicCardCount = styled.span`
color:  rgba(186, 153, 118, 1);
font-family: "Open Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-left:30px;
padding-top:5px;
`;

export const AcademicCardText = styled.span`
color: rgba(186, 153, 118, 1);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px;
padding-top:30px;
padding-left:15px;
padding-right:30px;
`;


export const TuitionCardTitle = styled.div`
 color: var(--Final-Dark-Blue, #253872);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom:5px;
`;

export const TuitionCardBox = styled.div`
border-radius: 4px;
background-color:rgba(37, 56, 114, 0.04);
border-top:4px solid  var(--Final-Dark-Blue, #253872);
display:flex;
height:80px;
width:200px;
`;

export const TuitionCardCount = styled.span`
color: var(--Final-Dark-Blue, #253872);
font-family: "Open Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-left:30px;
padding-top:5px;
`;

export const TuitionCardText = styled.span`
color: var(--Final-Dark-Blue, #253872);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 16px;
padding-top:30px;
padding-left:15px;
padding-right:30px;
`;
