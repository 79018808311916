import React, { useState } from 'react';
import styled from '@emotion/styled';
import uploadIcon from '../../Images/uploadPurpleIcon.svg';
import SchoolUploadModal from './SchoolUploadModal';


type Props = {
    projectDescription: string;
    projectId: number;
    schoolId: number | null;
    onClose: () => void;
}
const Wrapper = styled.div`
cursor:pointer;
padding-left:20px`;




const Icon = styled.img`
width: 25px;

max-width: 100%;
overflow: hidden;
height: 25px;
margin-top: 3px;
margin-left: 5px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:11px; 

`;
const SchoolUploadIcon = ({ projectDescription, projectId, schoolId, onClose }: Props) => {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const onUploadModalClose = async () => {
        setOpenUploadModal(false);
        onClose();
    }

    return (
        <Wrapper onClick={() => setOpenUploadModal(true)}>  <Icon src={uploadIcon} alt="Upload Icon" />
            <UploadText>Upload</UploadText>
            {openUploadModal && <SchoolUploadModal onClose={onUploadModalClose} schoolId={schoolId} projectDescription={projectDescription} projectId={projectId} />}</Wrapper>
    );
};
export default SchoolUploadIcon;
