import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { TimelineItem } from '../../globalTypes/objects';
import EditStatus from './EditStatus';
import { useToken } from "../../useToken";
import SchoolUploadIcon from './SchoolUploadIcon';
import SchoolProcessing from '../Processing/SchoolProcessing';
import SelectedSchoolContext from '../SelectedSchoolContext';
import SchoolDownloadIcon from './SchoolDownloadIcon';
import { NoteButton } from '../Processing/ProcessingStyles';
import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FilterButton, HeaderCell, BodyCell, OverdueStatusBodyCell, DocumentBodyCell, FilterText, FilterButtonText } from './ProjectsStyles';
import ProjectItem from './ProjectCell';
import StatusCell from './StatusCell';
import { MdEdit, MdFlag, MdPartyMode, MdWarning } from 'react-icons/md';
import filterIcon from '../../Images/filterIcon.svg';
import partyIcon from '../../Images/partyIcon.svg';
import warningIcon from '../../Images/warningIcon.svg';
import moment from 'moment';
import FilterCounter from './FilterCounter';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    minWidth: 175,
    verticalAlign: 'center'

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Projects = () => {
    const [timeline, setTimeline] = useState<TimelineItem[]>();
    const [filteredData, setFilteredData] = useState<TimelineItem[]>();
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [selectedProject, setSelectedProject] = useState<string | null>(null);
    const [upcomingCounter, setUpcomingCounter] = useState<number>(0);
    const [historyCounter, setHistoryCounter] = useState<number>(0);
    const [viewAllCounter, setViewAllCounter] = useState<number>(0);
    const [roles, setRoles] = useState<string[] | string>();
    const [selectedStatus, setSelectedStatus] = useState<string>('upcoming');
    moment.locale('en-us');
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
   // const { isLoadingSchools, selectedSchoolId, selectedSchoolName } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;

  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getTimeline = async (isInitial: boolean) => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getTimelineYearlyOverview/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setTimeline(data);
            const now = new Date().setHours(0, 0, 0, 0);
            const initialLoadData = data?.filter((item: { deadlineDate: string; }) => new Date(item.deadlineDate).setHours(0, 0, 0, 0) >= now)
            const historyData = data?.filter((item: { deadlineDate: string; }) => new Date(item.deadlineDate).setHours(0, 0, 0, 0) < now)
            if (isInitial) {
                setFilteredData(initialLoadData);
            }
            else { updateTimeline(selectedStatus); }
       
            const countUpcoming = initialLoadData.length;
            setUpcomingCounter(countUpcoming);
            const countHistory = historyData.length;
            setHistoryCounter(countHistory);
            setViewAllCounter(countUpcoming + countHistory);

        } catch (error: any) {
            //todo
            console.log(error.message);
        }
    };


    const updateTimeline = (selectedStatusFilter: string) => {
        const now = new Date().setHours(0, 0, 0, 0);
      //  console.log(selectedStatus);
        const filteredTimeline = selectedStatusFilter.toLowerCase() === "upcoming" ? timeline?.filter(item => new Date(item.deadlineDate).setHours(0, 0, 0, 0) >= now)
            : timeline?.filter(item => new Date(item.deadlineDate).setHours(0, 0, 0, 0) < now);
        setFilteredData(filteredTimeline);


    };

    const handleButtonClick =  (selectedStatusFilter: string) => {
        setSelectedStatus(selectedStatusFilter);
        updateTimeline(selectedStatusFilter);
       
    };

    const handleEditClick = (itemId: number, project: string) => {
        setSelectedItemId(itemId);
        setSelectedProject(project);
    };

    const handleModalClose = () => {
        getTimeline(false);
    };

    const handleEditModalClose = async () => {
        setSelectedItemId(null);
        setSelectedProject(null);
      getTimeline(false); //selectedStatus;
        // setSelectedStatus(selectedStatus);
     //   handleButtonClick(selectedStatus);

    };
    const getDateReceivedIcon = async () => {
        const now = new Date().setHours(0, 0, 0, 0);

    }

    const formatDate = (dateString: string) => {
        const date = moment(dateString);

        let formatedDate = date.format("MMMM DD, YYYY");
        formatedDate = formatedDate == 'Invalid date' ? '' : formatedDate;
        
        return formatedDate;

    };

    const setUserRoles = async () => {
        const localToken = await getToken();
        setRoles(localToken?.roles);

    };

    useEffect(() => {
        getTimeline(true).catch(error => {
            console.error('Error getting timeline:', error);
        });
        setUserRoles();
      //  updateTimeline(selectedStatus);
    }, []);



    return (
        <div>
            <div className="pageHeader">Projects and Reports</div>

            <TableContainer sx={{ width: '85%', maxWidth: '1400px', overflow: 'auto', padding: '5px', marginTop: '38px', borderRadius: '20px', boxShadow: '0px 0px 20px #8397f821' }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <img src={filterIcon} alt="Filter Icon" /><FilterText>Filter</FilterText>
                                    <FilterButton style={selectedStatus == 'upcoming' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => handleButtonClick('upcoming')}>
                                        <FilterButtonText >Upcoming</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={upcomingCounter} filterName = 'upcoming' /></FilterButton>
                                    <FilterButton style={selectedStatus == 'history' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => handleButtonClick('history')}>
                                        <FilterButtonText>History</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={historyCounter} filterName='history' /></FilterButton>
                                    <FilterButton style={selectedStatus == 'viewall' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => (setSelectedStatus('viewall'), setFilteredData(timeline))}>
                                        <FilterButtonText>View All</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={viewAllCounter} filterName='viewall' /></FilterButton>    </div>
                            </TableCell>

                        </TableRow>

                        <TableRow>
                            <StyledTableCell><HeaderCell style={{ width:'180px' }}>Project/Report</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>HES Deadline</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Deadline</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Date Received</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Download/Upload</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Status</HeaderCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData &&
                            filteredData.map(x => (
                                <TableRow
                                    key={x.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        <ProjectItem projectDescription={x.taskDescription} status={x.status} />
                                    </StyledTableCell>
                                    <StyledTableCell ><BodyCell >{formatDate(x.hesDueDate)}
                                        </BodyCell></StyledTableCell>
                                    <StyledTableCell ><BodyCell>{formatDate(x.deadlineDate)}</BodyCell></StyledTableCell>
                                    <StyledTableCell ><BodyCell style={{ display: 'flex' }}><div style={{ paddingLeft: '25px', width: '55%' }}>{formatDate(x.dateReceived)}</div>
                                        <div style={{  width:'5%' }}>
                                            {x.dateReceived && new Date(x.dateReceived).setHours(0, 0, 0, 0) <= new Date(x.hesDueDate).setHours(0, 0, 0, 0) ? <span style={{ fontSize: '18px' }}>🎉</span> :
                                                new Date().setHours(0, 0, 0, 0) >= new Date(x.hesDueDate).setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) < new Date(x.deadlineDate).setHours(0, 0, 0, 0) ?
                                                 <MdWarning fontSize={'20px'} color={'#ffcb00'}></MdWarning> :
                                                    x.status == 'Overdue' ? <MdFlag fontSize={'18px'} color={'rgb(251, 74, 74)'}></MdFlag>:<span></span>
                                            
                                        }</div>  
                              {/*   test       {<span style={{ fontSize: '18px' }}>⚠️</span> }*/}
                             
                                     
                                    </BodyCell></StyledTableCell>
                                    <StyledTableCell style={{ display: 'flex' }}><DocumentBodyCell>
                                        <SchoolDownloadIcon documentIds={x.documentIds} filePath={x.filePath} fileName={x.fileName} projectId={x.id} schoolId={selectedSchoolId} projectDescription={x.taskDescription} />
                                        <SchoolUploadIcon projectDescription={x.taskDescription} projectId={x.id} schoolId={selectedSchoolId} onClose={() => handleModalClose()} /></DocumentBodyCell></StyledTableCell>
                                    {/*<StyledTableCell onClick={() => (roles?.includes('SuperAdmin') || roles?.includes('Admin') || roles?.includes('Processor')) ? handleEditClick(x.id, x.taskDescription) : {}} >*/}{/* <MdEdit color='#253872' fontSize="large" onClick={() => handleEditClick(x.id)} />*/}
                                    <StyledTableCell>   <StatusCell status={x.status} projectId={x.id} projectNotes={x.timelineNotes[0] != null ? x.timelineNotes : []} projectDescription={x.taskDescription} onStatusClick={() => (roles?.includes('SuperAdmin') || roles?.includes('Admin') || roles?.includes('Processor')) ? handleEditClick(x.id, x.taskDescription) : {}} isCard={false } /> 
                                        </StyledTableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {selectedItemId && selectedProject &&
                <EditStatus
                    id={selectedItemId} projectDescription={selectedProject}
                    onClose={() => handleEditModalClose()} />}

        </div>

    );
};

export default Projects;

