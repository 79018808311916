import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Need } from '../../globalTypes/objects';
import SubmitButton from '../SubmitButton';
import {useAuth0} from "@auth0/auth0-react";
import {useToken} from "../../useToken";
import NeedItem from './NeedItem';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { API_BASE_URL } from '../../urlConfig';

const Input = styled.input`
  padding: 10px;
  font-size: 18px;
  width: 355px;
  box-sizing: border-box;
`;

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number;
    needs: Need[];
   /* onClose: () => void;*/
}

const AddNote = ({ studentName, studentId, schoolName, schoolId, needs }: Props) => {
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidNote, setInvalidNote] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState('');
    const [needRequired, setNeedRequired] = useState(false);

    const [selectedNeeds, setSelectedNeeds] = useState<number[]>([]);

   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const handleCheckboxChange = (id: number) => {
        if (selectedNeeds.includes(id)) {
            setSelectedNeeds(selectedNeeds.filter((needId) => needId !== id));
        } else {
            setSelectedNeeds([...selectedNeeds, id]);
        }
    };



    const handleNoteChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        setNote(inputValue);

        if (inputValue.length >= 255) {
            setInvalidNote(true);
        } else {
            setInvalidNote(false);
        }
    };
    const getToken = useToken();
   
    const handleAddNote = async () => {
        if (selectedNeeds.length > 0) {
            const localToken = await getToken();
            setNeedRequired(false);
            setLoading(true);
            axios.post(`${API_BASE_URL}/api/note/AddNoteForNeedId/${schoolId}/${studentId}`, { Note: note, Type: needs[0].type, NeedIdList: selectedNeeds }
               , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    console.log(response);
                    setSuccess(true);
                    setLoading(false);
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    setLoading(false);
                })
        } else {
            setNeedRequired(true);
        }
    }
    useEffect(() => {
        if (needs.length == 1) {//default checkbox to checked when there is only 1 need.
            handleCheckboxChange(needs[0].id);
        }
    }, []);

    return (
        <div style={{ width: '100%' }}>

            {success ?
                (<div style={{ color: '#5EB234' }}>Comment Added Successfully!</div>)
                :
                (<><div style={{ textAlign: 'left' }}>
                    {needs.map((need) => (
                        <div key={need.id} style={{
                            display: 'flex', padding:'5px'
                            }}>
                            <input
                                type="checkbox"
                                id={`need-${need.id}`}
                                checked={selectedNeeds.includes(need.id)}
                                onChange={() => handleCheckboxChange(need.id)}
                            />
                            <label style={{ paddingLeft: '10px', paddingRight:'15px', width:'100%' }} htmlFor={`need-${need.id}`}><NeedItem need={need} /></label>
                        </div>
                    ))}
                </div>
            
                    {needRequired && (
                        <div>
                            <p style={{ color: 'red' }}>Please select 1 or more items to associate with the comment.</p>
                        </div>
                    )}
                    <div style={{ paddingTop: '50px' }}>
                    <TextField
                            id="outlined-multiline-flexible"
                            label="Add Comment Here"
                            multiline rows={3 }
                             onChange={handleNoteChange} fullWidth 
                        />
                    </div></>)}
            

            {invalidNote && (
                <div>
                    <p style={{ color: 'red' }}>Comment is too long</p>
                </div>
            )}

            {response && (
                <div>
                    <p style={response == "Success" ? { color: 'green' } : { color: 'red', width: '600px' }}>Response: {response}</p>
                </div>
            )}

            {note && !success && (
                <div style={{
                   height: '60px', paddingTop: '15px', textAlign: 'center'
                }}>
               
                   

                    <Button onClick={handleAddNote} variant="contained" disabled={loading || invalidNote} style={{  backgroundColor: '#253872' }}>Submit
                    </Button> 
                </div>
            )}
        </div>
    );
};

export default AddNote;