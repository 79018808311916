/*
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

*/

// index.tsx
// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from "./App";

import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from react-dom/client
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import "@fontsource/open-sans";
import "@fontsource/inter";
import "./styles.css";
import { Provider } from "react-redux";
import { store } from "./store";
import ReduxProvider from './store/redux-provider';

// Assuming your root element has an ID of 'root'
const rootElement = document.getElementById('root');

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <Auth0Provider
                // domain="dev-cj7ksggfbon5reb8.us.auth0.com"
                domain="auth.higheredsolutionsonline.com"
                clientId="gaZQknq3O8CrSVeHNPEvj3yMHe4PxhLu"
                authorizationParams={{
                    redirect_uri: window.location.origin + '/home',
                    audience: 'https://higheredsolutionsonline.com'
                }}>
                <ReduxProvider>
                    <App />
                </ReduxProvider>
            </Auth0Provider>
        </React.StrictMode>
    )
}

//  onRedirectCallback="https://localhost:44434/home">