import styled from "@emotion/styled";

export const MonthColumn = styled.div`
border-radius: 10px;
background: var(--White, #FFF);
box-shadow: 0px 0px 20px 0px rgba(131, 151, 248, 0.13);
width: 350px;
border: 1px solid rgba(37, 56, 114, 0.14);
  @media (max-width:880px){
        width: 300px;
    }

`;

export const MonthHeader = styled.div`
 color: #253872;
    font-size: 20px;
    font-family: Open Sans;
    font-weight: 700;
    background-color: rgba(131, 151, 248, 0.15);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height:50px;
    align-content:center;
    padding-left:25px;

`;

export const MonthItem = styled.div`

padding-left:15px;

font-size: 14px;

`;

export const EndOfMonthHeader = styled.div`
 color: #253872;
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 700;
    border: 1px solid rgba(131, 151, 248, 0.15);
    height:50px;
    align-content:center;
    padding-left:25px;

`;

//export const colors = {
//    Done: '#F1F1E6',
//    notDone: '#E8A530',
//    highPriority: '#ffadad',
//    midPriority: '#ffd6a5',
//    lowPriority: '#caffbf',
//    cellBackground: '#F1F1E6',
//    borderColor: '#ffffff'
//};

//export const customStyles = {
//    container: (provided: any) => ({
//        ...provided,
//        width: 325,
//    }),
//    control: (provided: any, state: any) => ({
//        ...provided,
//        boxShadow: state.isFocused && '1px solid #a1a8ca',
//        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
//        height: 48,
//        '&:hover': {
//            border: state.isFocused && '1px solid #a1a8ca',
//        },
//    }),
//};
