import React, { useContext, useState, useEffect } from 'react';
import AnnouncementsCard from "./Announcements/AnnouncementsCard";
import ProcessingCard from "./Processing/ProcessingCard";
import styled1 from "@emotion/styled";
import TimelineCard from "./YearlyOverview/YearlyOverviewCard";
import ProjectsReportsCard from "./Projects/ProjectsReportsCard";
import { Box, Grid, Paper } from '@mui/material';
/*import { Item } from 'semantic-ui-react';*/
import { styled } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react';
import { useToken } from '../useToken';
import axios from 'axios';
import "@fontsource/inter";
import '../styles.css';
import NotificationsCard from './Notifications/NotificationsCard';

const Wrapper = styled1.div`
 
`


 const GridItemStyled = styled(Grid)`
  &.MuiGrid-item {
    padding-top: 64px;
     @media (max-width:880px){
      padding-top: 64px;
         }
  }
`;

const Home = () => {


    const [roles, setRoles] = useState<string[]|string>();
    const [error, setError] = useState('');
    const getToken = useToken();
    //const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);
       
    };
    
    useEffect(() => {
        setUserRoles();
    }, []);
    return (
        <Wrapper>

            <div className="pageHeader" >Dashboard</div>
            <div style={{ marginTop:'2%' }}>
            <Grid container spacing={8}  columns={{ xs: 3, sm: 5, md: 8, lg: 10 }}>
                <GridItemStyled item xs={2} sm={3} md={3} lg={4 } >
                    <AnnouncementsCard />
                </GridItemStyled>
                <GridItemStyled item xs={2} sm={3} md={3} lg={4}>
                   <ProcessingCard />
                </GridItemStyled>
                <Grid  item xs={2} sm={3} md={3} lg={4}>
                 <NotificationsCard />
                </Grid>
                <Grid item xs={2} sm={3} md={3} lg={4}>
                      <ProjectsReportsCard />  
                </Grid>
            </Grid>
</div>
            {/*<CardWrapper>*/}

               
                   
            {/*    <CardHolder >*/}
            {/*        <AnnouncementsCard />*/}
            {/*        <ProcessingCard />*/}
            {/*    </CardHolder>*/}
            {/*    <CardHolder>*/}
            {/*        <ProjectsReportsCard />*/}
            {/*        <TimelineCard />*/}
               
            {/*    </CardHolder>*/}
            {/*</CardWrapper>*/}

        </Wrapper>
    );

};

export default Home;
