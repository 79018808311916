import React, { useEffect, useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { CloseButton } from '../CloseButton';
import SchoolSelector from '../SchoolSelector';
import MultipleSchoolSelector from './MultipleSchoolSelector';
import { Button } from '@mui/material';
import { useToken } from '../../useToken';
import { MultiValue } from 'react-select';
import { School, UserModel } from '../../types';
import axios from 'axios';
import EditUser from './EditUser';
import UploadFile from '../UploadFile';

type Props = {
    userId: string;
    imgUrl: string;
    onClose: () => void;
   
}

const UploadProfileImageModal = ({ onClose, userId, imgUrl }: Props) => {
   // const selectedSchools: (string | MultiValue<{ label: string; value: number; }>)[] = [];

    const [success, setSuccess] = useState<boolean>(false);


    const onSuccess = () => {
        setSuccess(true);
      //  imgUrl = 'api/document/uploadUserProfileImage/${userId}';

    };


    useEffect(() => {
       // getSchools();
        console.log();
    }, []);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Upload Profile Image</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody style={{ height: '200px' }}>
                    <UploadFile
                        uploadPath={`api/document/uploadUserProfileImage/${userId}`}
                        onSuccess={() => onSuccess() }
                        acceptedFileTypes={
                            {
                                'image/jpeg': ['.jpeg','.jpg'],
                                'image/png': ['.png'],
                            }
                        } /> 
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default UploadProfileImageModal;
