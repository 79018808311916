import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import axios from 'axios';
import {  School, SchoolHistoryRecord, StudentHistoryRecord } from '../../globalTypes/objects';
import { SchoolHistoryContainer, SchoolHistoryTable, StudentHistoryContainer, StudentHistoryTable, TableCell, TableHeader } from '../Processing/ProcessingStyles';
import { useToken } from '../../useToken';
import SchoolSelector from "../SchoolSelector";
import SelectedSchoolContext from "../SelectedSchoolContext";
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';

const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 255,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};

const History = () => {
    const [schoolHistory, setSchoolHistory] = useState<SchoolHistoryRecord[]>();
    const [studentHistory, setStudentHistory] = useState<StudentHistoryRecord[]>();
    const [selectedSchool, setSelectedSchool] = useState<number>();
    const [schools, setSchools] = useState<School[]>();

   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const getSchools = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/School/getAll`, {headers:
            {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localToken?.accessToken}`
            }});
            setSchools(data);
        } catch (error: any) {
            //todo
        }
    };

    useEffect(() => {
        getSchools()
    }, []);
    
    const getToken = useToken();
    //const getSchoolHistory = async () => {
    //    try {
    //        const localToken = await getToken();
    //        if (selectedSchoolId) {
    //            const { data } = await axios.get(`${API_BASE_URL}/api/Processing/getSchoolHistory/${selectedSchoolId}`,{headers:
    //                    {
    //                        'Content-Type': 'multipart/form-data',
    //                        'Authorization': `Bearer ${localToken?.accessToken}`
    //                    }});
    //            setSchoolHistory(data);
    //        }
    //    } catch (error: any) {
    //        //todo
    //    }
    //};

    const getStudentHistory = async () => {
        
        try {
            const localToken = await getToken();
            if (selectedSchoolId) {
                const { data } = await axios.get(`${API_BASE_URL}/api/Processing/getStudentHistory/${selectedSchoolId}`,{headers:
                        {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }});
                setStudentHistory(data);
            }
        } catch (error: any) {
            //todo
        }
    };

    useEffect(() => {
        //getSchoolHistory();
        getStudentHistory();
    }, [selectedSchoolId]);

    return (
        <div>
            <div className="pageHeader">History</div>   
            {/*<div style={{ display: 'flex', gap: '24px' }}>*/}
            {/*    <SchoolSelector/>*/}
            {/*    */}{/*<Select*/}
            {/*    */}{/*    options={schools?.map(s => ({*/}
            {/*    */}{/*        label: s.name,*/}
            {/*    */}{/*        value: s.id,*/}
            {/*    */}{/*    }))}*/}
            {/*    */}{/*    onChange={(e) => setSelectedSchool(e?.value)}*/}
            {/*    */}{/*    styles={customStyles}*/}
            {/*    */}{/*    placeholder="Select School"*/}
            {/*    */}{/*/>*/}
            {/*</div>*/}
            <br />
            {studentHistory &&
                <StudentHistoryContainer>
                    <h2>Student History Grid</h2>
                    <StudentHistoryTable>
                        <thead>
                            <tr>
                                <TableHeader>Student Name</TableHeader>
                                <TableHeader>External Student ID</TableHeader>
                                <TableHeader>Need</TableHeader>
                                <TableHeader>Need Type</TableHeader>
                                <TableHeader>Need Category</TableHeader>
                                <TableHeader>Uploaded</TableHeader>
                                <TableHeader>Deleted At</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {studentHistory.map((record, index) => (
                                <tr key={index}>
                                    <TableCell>{record.studentName}</TableCell>
                                    <TableCell>{record.externalStudentId}</TableCell>
                                    <TableCell>{record.need}</TableCell>
                                    <TableCell>{record.needType}</TableCell>
                                     <TableCell>{record.uploaded ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{record.deletedAt}</TableCell>
                                </tr>
                            ))}
                        </tbody>
                    </StudentHistoryTable>
                </StudentHistoryContainer>
            }

            {schoolHistory &&
                <SchoolHistoryContainer>
                    <h2>School History Grid</h2>
                    <SchoolHistoryTable>
                        <thead>
                            <tr>
                                <TableHeader>Need</TableHeader>
                                <TableHeader>Need Type</TableHeader>
                                <TableHeader>Program Course ID</TableHeader>
                                <TableHeader>Program Course Name</TableHeader>
                                <TableHeader>Uploaded</TableHeader>
                                <TableHeader>Deleted At</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {schoolHistory.map((record, index) => (
                                <tr key={index}>
                                    <TableCell>{record.need}</TableCell>
                                    <TableCell>{record.needType}</TableCell>
                                    <TableCell>{record.programCourseId}</TableCell>
                                    <TableCell>{record.programCourseName}</TableCell>
                                    <TableCell>{record.uploaded ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{record.deletedAt}</TableCell>
                                </tr>
                            ))}
                        </tbody>
                    </SchoolHistoryTable>
                </SchoolHistoryContainer>
            }

        </div>
    );
};

export default History;
