import React, {useContext, useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import { useToken } from '../useToken';
import SelectedSchoolContext from './SelectedSchoolContext';
import { setSchoolState } from '../store/schoolSlice';
import { useAppSelector, useAppDispatch } from "../store/index";
import { API_BASE_URL } from '../urlConfig';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

//const contextValue = useContext(SelectedSchoolContext);
//if (!contextValue) {
//    throw new Error("SelectedSchoolContext is not provided");
//}
//    const { isLoadingSchools, setIsLoadingSchools, selectedSchoolId, setSelectedSchoolId, selectedSchoolName, setSelectedSchoolName } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const getToken = useToken();
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getSchoolsForUser = async () => {
        const localToken = await getToken();
      //  setIsLoadingSchools(true);
        try {
            fetch(`${API_BASE_URL}/api/School/getSchoolsForUser`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                   // setSchools(data);
                //    setIsLoadingSchools(false);

                    if ((schoolState.id == null || schoolState.id == 0) && data.length > 0) {
                     //   setSelectedSchoolId(data[0].id);
                      //  setSelectedSchoolName(data[0].name);
                        dispatch(setSchoolState({ name: data[0].name, id: data[0].id }));
                    }// Set the first school as the default
                });
        }
        catch ({ message }) {
            console.log('An unknown error occurred' || message);
        } finally {
         //   setIsLoadingSchools(false);
        }
    }
    useEffect(() => {
        if (!isAuthenticated) {
            // Redirect to Auth0 login page
            loginWithRedirect();
        } else {
            getSchoolsForUser();
            navigate('/home')
        }
      }, [isAuthenticated, loginWithRedirect]);

    return <div>Loading...</div>; // You can display a loading message or spinner
};

export default Login;

/*

const OuterLogIn = styled.div`
  background-color: #fff;
  width: 500px;
  height: 700px;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #1f2b5e;
  padding: 24px;
`;

const Overlay = styled.div`
  background-color: #fffcfc;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  z-index: 10000;
`;
const SignInButton = styled.button`
    border: 1px solid #1f2b5e;
    width: 400px;
    padding: 24px;
    background-color:#fff;
    justify-content:center;
    font-size: 20px;
    display:flex;
    align-items:center;
    margin-left: 50px;
    cursor:pointer;
`;
const Login = () => {
    const {loginWithRedirect} = useAuth0();

    return (
        <OuterLogIn>
            <Overlay/>
            <div style={{color: '#1f2b5e', fontSize: 34, textAlign: 'center', fontWeight: 'bold', marginTop: 50}}>HECM
            </div>
            <SignInButton style={{marginTop: 100}}> <FcGoogle/> <span
                style={{marginLeft: 16}}>Sign in with Google</span></SignInButton>
            <SignInButton style={{marginTop: 50}}> <BsMicrosoft/> <span
                style={{marginLeft: 16}}>Sign in with Office 365</span></SignInButton>
            <button onClick={() => loginWithRedirect()}>Log In With Auth0</button>

        </OuterLogIn>
    )
};

export default Login;*/
