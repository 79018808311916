import React, { ComponentType } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage';
import Home from "./components/Home";
import DefaultLayout from "./components/DefaultLayout";
import Admin from "./components/Admin/Admin";
import ManageUsers from "./components/Admin/ManageUsers";
import ViewUsers from "./components/Admin/ViewUsers";
import CreateUser from "./components/Admin/CreateUser";
import History from "./components/History/History";
import TestUpload from './components/TestUpload';
import Login from "./components/Login";
import Help from "./components/Help";
import Announcements from "./components/Announcements/Announcements";
import AnnouncementViewer from "./components/Announcements/AnnouncementViewer";
//import Notifications from "./components/Notifications/NotificationsCard";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ProcessingNew from './components/Processing/ProcessingNew';
import YearlyOverview from './components/YearlyOverview/YearlyOverview';
import Projects from './components/Projects/Projects';
import SwitchSchool from './components/Admin/SwitchSchool';
import AccountSettings from './components/Admin/AccountSettings';
import TestRedux from './components/testRedux';
import Test from './components/TestUpload';
import Folders from './components/Folders/Folders';
import Feedback from './components/Feedback/Feedback';

interface CustomRouteProps {
    path: string;
    exact?: boolean;
    component: React.ComponentType<any>;
    isProtected?: boolean;
    // We can add other properties as needed (e.g., protected, noLayout, authorizedRoles)
}

interface ProtectedRouteProps {
    component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
    const Component = withAuthenticationRequired(component);
    return <Component />;
};
// Define routes
const routes: CustomRouteProps[] = [
    { path: "/login", exact: true, component: Login },
    { path: "/", exact: true, component: Login },
    { path: "/home", exact: true, component: Home, isProtected: true },
    { path: "/processing", exact: true, component: ProcessingNew, isProtected: true },
    //{ path: "/timelineNew", exact: true, component: TimelineNew, isProtected: true },
    //{ path: "/timeline5", exact: true, component: Timeline, isProtected: true },
    //{ path: "/timeline1", exact: true, component: Timeline, isProtected: true },
    //{ path: "/timeline2", exact: true, component: Timeline, isProtected: true },
    //{ path: "/timeline3", exact: true, component: Timeline, isProtected: true },
    //{ path: "/timeline4", exact: true, component: Timeline, isProtected: true },
    { path: "/feedback", exact: true, component: Feedback, isProtected: true },
    { path: "/yearlyoverview", exact: true, component: YearlyOverview, isProtected: true }, 
    { path: "/projects", exact: true, component: Projects, isProtected: true },
    { path: "/test", exact: true, component: Test, isProtected: true },
    { path: "/admin", exact: true, component: Admin, isProtected: true },
    { path: "/manageusers", exact: true, component: ManageUsers, isProtected: true },
    { path: "/createuser", exact: true, component: CreateUser, isProtected: true },
    { path: "/viewusers", exact: true, component: ViewUsers, isProtected: true },
    { path: "/switchschool", exact: true, component: SwitchSchool, isProtected: true },
    { path: "/accountsettings", exact: true, component: AccountSettings, isProtected: true },
    { path: "/history", exact: true, component: History, isProtected: true },
    { path: "/announcements", exact: true, component: Announcements, isProtected: true },
    { path: "/announcementviewer", exact: true, component: AnnouncementViewer, isProtected: true },
    { path: "/folders", exact: true, component: Folders, isProtected: true },
    { path: "/help", exact: true, component: Help, isProtected: true },
    { path: "/notfound", exact: true, component: NotFoundPage, isProtected: true },
    { path: "*", component: NotFoundPage }, // Fallback route

];

const RouterComponent = () => {
    return (

        <Router>
            <DefaultLayout>
                <Routes>
                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                    {routes.map(({ path, exact, component: Component, isProtected }) => (
                        <Route
                            key={path}
                            path={path}
                            // element={<Component/>}
                            element={isProtected ? <ProtectedRoute component={Component} /> : <Component />}
                        />

                    ))}
                </Routes>
            </DefaultLayout>
        </Router>

    );
};


export default RouterComponent;

