import React from "react";
import '../styles.css';
import closeIcon from '../Images/closeIcon.svg';

type Props = {
    onClick: () => void;
}
export const CloseButton = ({onClick }: Props): JSX.Element => {
    return (
        <div className="closeButton" onClick={onClick}>
            <img className="closeIcon" alt="close" src={closeIcon} />
        </div>
    );
};