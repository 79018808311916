import React, { useState } from 'react';
import { Paper,  TableContainer, Table } from '@mui/material';


import AnnouncementsTable from './AnnouncementsTable';
import { SearchBar } from '../SearchBar';




const Announcements = () => {
    const [searchValue, setSearchValue] = useState<string>("");
    return (
       
            <div>  <div className="pageHeader">Announcements</div>
            {/*  <div>   <SearchBar placeholder={'Search announcements'} searchValue={searchValue}></SearchBar></div>*/}


            {/*{searchValue}*/}
            <TableContainer sx={{
                width: '85%', maxWidth: '1400px', minHeight: '100px', overflow: 'auto', padding: '0px', marginTop: '38px', borderRadius: '20px', background: 'white', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)'
            }} component={Paper}>
                <Table>
                    <AnnouncementsTable />
                </Table>
            </TableContainer>


            

        </div>

    );
};

export default Announcements;

