import React, { useEffect, useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { CloseButton } from '../CloseButton';
import SchoolSelector from '../SchoolSelector';
import MultipleSchoolSelector from './MultipleSchoolSelector';
import { Button } from '@mui/material';
import { useToken } from '../../useToken';
import { MultiValue } from 'react-select';
import { School } from '../../types';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    userId: string;
    userName: string;
    onClose: () => void;
    selectedSchools: School[];
}

const AddSchoolModal = ({ onClose, userId, userName, selectedSchools }: Props) => {
   // const selectedSchools: (string | MultiValue<{ label: string; value: number; }>)[] = [];
    const getToken = useToken();
    //const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const [response, setResponse] = useState<string>('');
    const AddSchools = async () => {
        const schoolIdList: number[]= [];
        selectedSchools.map((school: School) => schoolIdList.push(school.id));
        console.log( 'adding');
        console.log( selectedSchools);
        if (selectedSchools.length > 0) {
            const localToken = await getToken();
            
            axios.post(`${API_BASE_URL}/api/school/AddUserSchool/${userId}`, { SchoolIdList: schoolIdList }
                , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    console.log(response);
                    setResponse("Successfully updated user schools.");
                   
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    
                })
        } else {
            setResponse("Please select a school.");
        }
    }
    useEffect(() => {
       // getSchools();
        console.log(selectedSchools);
    }, [selectedSchools]);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Update Schools</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody style={{ height:'900px'} }>
                    Select School to add to {userName}
                    <br />
                    <MultipleSchoolSelector selectedSchools={selectedSchools} />
                    <Button style={{ marginTop: '205px', backgroundColor:"#253872" }} variant='contained' onClick={() => (AddSchools())}>Update User Schools</Button>
                    {response && <div>{response}</div>}
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default AddSchoolModal;
