import React, { useState } from 'react';
import styled from '@emotion/styled';
import commentIcon from '../../Images/commentIcon.svg';


const Wrapper = styled.div`
cursor:pointer`;


const Icon = styled.img`
width: 40px;

max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 8px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:12px;
margin-right: 2px;
`;

const UploadIcon = () => {

    return (
        <Wrapper>

            <Icon src={commentIcon} alt="Comment Icon" />
            <UploadText>Comment</UploadText>

        </Wrapper>
    );
};
export default UploadIcon;
