import React from 'react';

type Props = {
    importType: string;
}

const RequiredColumns = ({importType}: Props) => {

    const importColumns = [
        {
            importType: "StudentNeed",
            columns: "StudentId(Your Student Id),LastName(Students Last Name),FirstName(Students First Name),SchoolId(Your School Id), SchoolName(Your School Name), Need(FreeText), NeedType(FA/Tuition/Academic),Date"
        },
        {
            importType: "SchoolNeed",
            columns: "Need, NeedType, ProgramCourseId, ProgramCourseName"
        },
        {
            importType: "School",
            columns: "Name, ExternalSchoolId"
        },
        {
            importType: "Timeline",
            columns: "school_id, school_name, task_description (item that is due), deadline_type (category), deadline_date (only if category is actual),  hes_due_date,semester_module_type, deadline_month (only if category is monthly request)"
        },
        {
            importType: "Student",
            columns: "FirstName, LastName, StudentId"
        },
        {
            importType: "Announcement",
            columns: "Add in Announcement text and Select file for Announcement document."
        }
    ]

    return (
        <div style={{padding: 12}}>
            <div>
                <label style={{fontWeight: 'bold'}}>Required Columns: </label>
                <span>{importColumns.find(i => i.importType == importType)?.columns}</span>
            </div>
        </div>
    );
};

export default RequiredColumns;

