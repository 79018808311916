import styled from "@emotion/styled";

export const HeaderCellUser = styled.div`

color: #253872;
font-family: "Open Sans-Bold", Helvetica;
font-size: 16px;
font-weight: 700;

`


export const SendChangePasswordEmail = styled.div`

color: #72E889;
font-family: "Open Sans-Bold", Helvetica;
font-size: 16px;
margin-top:10px;
`