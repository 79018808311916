import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SelectedSchoolContext from "../SelectedSchoolContext";
import NotAuthorized from '../NotAuthorized';
import { useToken } from '../../useToken';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../store';



const ManageUser = () => {

    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;

    const [selectedImport, setSelectedImport] = useState<string>();
    // const [schools, setSchools] = useState<School[]>();
    const [selectedProjectId, setProjectId] = useState<number | null>(0);
    const [error, setError] = useState('');
    const [successfulUpload, setSuccessfulUpload] = useState(false);
    const [roles, setRoles] = useState<string[] | string>();
    const getToken = useToken();



    const navigate = useNavigate();


    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);

    };
    useEffect(() => {
        setUserRoles();
    }, []);

    if (roles?.includes('SuperAdmin')) {
   //     roles?.includes('HESAdmin') ||
        return (
            <>
                <div className="pageHeader">Manage Users</div>

                <br /><br />
                {(roles.includes("SuperAdmin")) &&//roles.includes("HESAdmin") ||
                    <div>
                        <Button onClick={() => navigate('/createuser')} variant="outlined" style={{ color: '#253872', borderColor: '#253872', }}>    Create User
                        </Button>
                        <Button onClick={() => navigate('/viewusers')} variant="outlined" style={{ color: '#253872', borderColor: '#253872',  marginLeft: '10px' }}>    View Users
                        </Button>

                    </div>}

            </>
        );
    }
    else {
        return (<NotAuthorized />);
    }
};

export default ManageUser;
