import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import { Form } from '../../globalTypes/objects';
import saveAs from 'file-saver';
import { useToken } from "../../useToken";
import DownloadIcon from './DownloadIcon';
import { API_BASE_URL } from '../../urlConfig';

const OuterWrap = styled.div`
  border-radius: 5px;
  border: 2px solid #b5b5b8;
  padding: 40px 24px;
  width: 250px;
`;

const InnerStyles = styled.div`
 display:flex;
 align-items:center;
 margin-top: 24px;
 cursor:pointer;
 
`


const ModalHeader = styled.div`
background-color:#8397F826;
font-weight:700;
color:#253872;
font-size:16px;
align-items:center;

`;



type Props = {
    processingType: string;
}

const DownloadForms = ({  processingType }: Props) => {
    const [forms, setForms] = useState<Form[]>([]);

   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getForms = async () => {
        try {
            const localToken = await getToken();
            const url = API_BASE_URL;
            const { data } = await axios.get(`${API_BASE_URL}/api/Document/getAllForms/${processingType}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setForms(data);
        } catch (error: any) {
            console.log(error.message);
            //setError(error.message || 'An unknown error occurred');
        }
    };

    const handleFormDownload = async (form: Form) => {
        try {
            const localToken = await getToken();
            //   const baseUrl = process.env.REACT_APP_API_BASE_URL;
            logDownloadForm(form.id);
            const response = await axios.get(`${API_BASE_URL}/api/Document/downloadFormDocument/${form.id}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });


            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            saveAs(blob, form.filePath);
        } catch (e) {
             console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logDownloadForm = async (formId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadFormLog/${formId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };

    //const handleFormDownload2 = async (form: Form) => {
    //    try {
    //        const localToken = await getToken();
    //        const baseUrl = process.env.REACT_APP_API_BASE_URL;
    //        const response = await axios.get(`${baseUrl}/api/Document/downloadFormDocument/${form.id}`, {
    //            responseType: 'arraybuffer',
    //            headers: {
    //                'Authorization': `Bearer ${localToken}`
    //            }
    //        });

    //        const blob = new Blob([response.data], { type: 'application/pdf' });
    //        saveAs(blob, form.filePath || 'download.pdf');
    //    } catch (e: any) {
    //        console.error('Download failed', e);
    //        alert('Download failed   ' + e.message);

    //    }
    //};

    useEffect(() => {
        getForms()
    }, []);

    return (
        <div>
            {
                forms.map(
                    (form) =>
                        <InnerStyles onClick={() => handleFormDownload(form)}>
                          
                            <div style={{ marginRight: 16 }}> <DownloadIcon /></div>
                            <span style={{ marginTop: 4 }}>{form.fileName}</span>
                        </InnerStyles>
                )
            }
        </div>
       

        //<OuterWrap>
            
        //</OuterWrap>
    );
};

export default DownloadForms;
