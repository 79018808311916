import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from 'jwt-decode';
import { User } from './globalTypes/objects';
/*import jsonwebtoken from "jsonwebtoken";*/

interface jwtToken {
    permissions?: string[] | string;
    scope?: string[] | string;
    // whatever else is in the JWT.
}

export const useToken = () => {
    const { getAccessTokenSilently,user } = useAuth0();

    return async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const tokenInfo = jwtDecode <jwtToken>(accessToken); // decode your token here
         
            const userProfile: User = {
                userName: user?.name + "_" + user?.family_name,
                email: user?.email ,
                accessToken: accessToken,
                roles: tokenInfo.permissions,
            };
            return userProfile;
        } catch (error) {
            console.error('Error getting access token', error);
            return null;
        }
    };
};