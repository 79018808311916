import React, { useEffect, useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { CloseButton } from '../CloseButton';
import SchoolSelector from '../SchoolSelector';
import MultipleSchoolSelector from './MultipleSchoolSelector';
import { Button } from '@mui/material';
import { useToken } from '../../useToken';
import { MultiValue } from 'react-select';
import { School, UserModel } from '../../types';
import axios from 'axios';
import EditUser from './EditUser';


type Props = {
    user: UserModel;
    userId: string;
    onClose: () => void;
   
}

const EditUserModal = ({ onClose, userId, user }: Props) => {
   // const selectedSchools: (string | MultiValue<{ label: string; value: number; }>)[] = [];
    const getToken = useToken();
    const [response, setResponse] = useState<string>('');
   
    useEffect(() => {
       // getSchools();
        console.log();
    }, []);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Edit User</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody style={{ height: '900px' }}>
                    <EditUser onClose={onClose} currentUserModel={user} />
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default EditUserModal;
