import React from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import AddNote from './AddNote';
import { Need, StudentRequirement } from '../../globalTypes/objects';
import StudentData from './StudentData';
import DownloadForms from './DownloadForms';
import { CloseButton } from '../CloseButton';

type Props = {
    processingType: string;
    onClose: () => void;
}

const DownloadFormsModal = ({ onClose, processingType }: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Download Forms</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody> <DownloadForms processingType={processingType} /></ModalBody>
               
            </ModalContent>
        </ModalOverlay>
    );
};

export default DownloadFormsModal;
