import React, { useEffect, useContext, useState } from 'react';
import { useToken } from "../../useToken";
import {   CircularProgress, TableBody, TableCell,TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { AnnouncementItem } from '../../globalTypes/objects';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { setAnnouncementState } from '../../store/announcementSlice';
import { API_BASE_URL } from '../../urlConfig';
import Loader from '../Loader';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    //  border: 0
    color: 'black',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(131, 151, 248, 0.07)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const AnnouncementsTable = () => {
    moment.locale('en-us');
    const announcementState = useAppSelector((state) => state.announcement);
    const dispatch = useAppDispatch();
    const [announcements, setAnnouncements] = useState<AnnouncementItem[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const baseUrl =  process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    //console.log('baseurl');
    //console.log(baseUrl);
    //console.log('API_BASE_URL');
    //console.log(API_BASE_URL);
    const getAnnouncements = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Announcement/getAnnouncements`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setAnnouncements(data);
            setLoading(false);
            setError('');
        } catch (error: any) {
            //todo
            setError(error.message);
            console.log(error.message);
            setLoading(false);
        }
    };
    const logAnnouncementUser = async (announcementId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/announcement/AddAnnouncementUserLog/${announcementId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };

    const openAnnouncement = async (announcementId: number) => {
     await logAnnouncementUser(announcementId);
        dispatch(setAnnouncementState(announcementId));
      
      //  window.open()
       // navigate( '/announcementviewer', { state: announcementId });
    };

    const formatDate = (dateString: string) => {
        const date = moment(dateString);
        const formatedDate = date.format("MMMM DD");
        return formatedDate;

    };
    const navigate = useNavigate();
    useEffect(() => {
        getAnnouncements().catch(error => {
            console.error('Error getting announcements:', error);
        });
    }, []);



    return (
     <>    {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft:'45%' }} />}
           
        <TableBody sx={{ padding: 0 }}>
            {announcements &&
                announcements.map(announcement => (
                    <StyledTableRow key={announcement.announcementId}>
                        <StyledTableCell >
                            <Link to='/announcementviewer' target="_blank" onClick={() => { openAnnouncement(announcement.announcementId); }}
                                style={{ color: '#000', cursor: 'pointer', textDecoration: 'auto' }}>
                                {announcement.wasOpened ? announcement.announcementText : <strong>{announcement.announcementText}</strong>}          
                            </Link>
                          
                        </StyledTableCell>
                        <StyledTableCell sx={{ color: '#7F96FF ', width:'100px' }}> {formatDate(announcement.dateCreated)}</StyledTableCell>
                    </StyledTableRow>
                ))}
        </TableBody></>
    );
};

export default AnnouncementsTable;

