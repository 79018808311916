import React from 'react';
import SchoolSelector from '../SchoolSelector';
import Button from '@mui/material/Button';


type Props = {
    onClose: () => void;
}

const SwitchSchool = ({ onClose }: Props) => {
    return (
        <div>  <div className="pageHeader">Switch School</div>
            <br />
            <br />
            <div>Please Select School:</div>
            <br />
            <SchoolSelector />
            {/*<div style={{ paddingTop: '40px' }}>*/}
            {/*    <Button onClick={onClose} variant="outlined" style={{ borderColor: '#253872', color: '#253872' }}>Ok*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
            );
};

            export default SwitchSchool;
