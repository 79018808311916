import React, { useState } from 'react';
import SchoolSelector from '../SchoolSelector';
import Button from '@mui/material/Button';
import { useAuth0, User } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { SendChangePasswordEmail } from './AdminStyles';
import { useToken } from '../../useToken';
import axios from 'axios';
import { UserModel } from '../../types';
import { IconButton, Tooltip } from '@mui/material';
import personIcon from '../../Images/personIcon.svg';
import UploadProfileImageModal from './UploadProfileImageModal';
import { API_BASE_URL } from '../../urlConfig';

const AccountSettings = () => {

    const { user } = useAuth0();
    const navigate = useNavigate();
    const getToken = useToken();
    const [success, setSuccess] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const profileIcon = personIcon;

    const [userModel, setUserModel] = useState<UserModel>({
        email: user?.email,
        //user_metadata: { name: "" },
        //blocked: false,
        //email_verified: false,
        //app_metadata: {},
        //given_name: "string",
        //family_name: "",
        //name: "",
        //nickname: "nickname",
        //picture: "https://example.com/image.jpg",
        //user_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        connection: "Username-Password-Authentication",
        //password: "",
        //verify_email: false,
        //schoolId: selectedSchoolId??0
    });

    const changePassword = async () => {

        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Login/changepassword`, userModel, {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    setSuccess(true);
                    setUserModel({
                        email: "",
                        connection: "Username-Password-Authentication",
                        
                    });
                } else {
                    console.log('Error changing password!  ');
                    console.log(res);
                    setSuccess(false);
                    alert(res.statusText + " Error changing password---  " + res.data);

                }
            })

            .catch((error) => {
                setSuccess(false);
                alert("Error changing password---  " + error?.response?.data);
            });
    }
    return (
        <div>
            <div className="pageHeader">Account Settings</div>
            <br />
            <br />
            {openProfileModal && <UploadProfileImageModal onClose={() => setOpenProfileModal(false)} userId={user?.sub??''} imgUrl={'' } />}

            <div style={{ marginLeft: '60px', marginBottom: '25px' }}>
                <Tooltip title="Select Icon" onClick={()=> (setOpenProfileModal(true))}>
                    <IconButton sx={{ backgroundColor: '#fff', p: 0, width: '80px', height: '80px' }}>
                       {user?.picture && user?.picture != "https://example.com/image.jpg" && <img width="52" height="52" src={user?.picture} alt="" />}
                        {(!user?.picture || user?.picture == "https://example.com/image.jpg") && <img width="52" height="52" src={personIcon} alt="" />}
                </IconButton>
            </Tooltip>
            </div>
            <div>Name: {user?.given_name +" "+ user?.name + " " + user?.family_name} </div> 
  
            <br />
            <div>Email: {user?.email} </div> 

            <br />
            <Button onClick={() => changePassword()} variant="contained" style={{ backgroundColor: '#253872' }}> Change Password Request
            </Button>
            <br />
            {success && <SendChangePasswordEmail> We have just sent you an email to reset your password.</SendChangePasswordEmail>}
        </div>
    );
};

export default AccountSettings;
