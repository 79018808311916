import React from 'react';
import { StudentRequirement, SchoolRequirement } from '../../globalTypes/objects';

type Props = {
    /* requirement: SchoolRequirement;*/
    projectDescription: string;
    projectId: number;
}

const SchoolData = ({ projectDescription, projectId }: Props) => {
    return (
        <div style={{ padding: 12 }}>
            <div>
                <label style={{ fontWeight: 'bold' }}>Project/Report: </label>
                <span>{projectDescription}</span>
            </div>

            {/*<div>*/}
            {/*    <label style={{ fontWeight: 'bold' }}>Requirement: </label>*/}
            {/*    <span>{requirement.need}</span>*/}
            {/*</div>*/}
        </div>
    );
};

export default SchoolData;
