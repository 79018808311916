import React from 'react';

const NotAuthorized = () => {
    return (
        <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>User does not have sufficient permissions to access this page on Hecm Client Portal</p>
        </div>
    );
};
export default NotAuthorized;
