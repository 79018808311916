import React, { useState } from 'react';
import styled from '@emotion/styled';
import uploadIcon from '../../Images/uploadPurpleIcon.svg';


const Wrapper = styled.div`
cursor:pointer`;


const Icon = styled.img`
width: 40px;

max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 6px;
`;
const UploadText = styled.div`
color: #7f96ff;
font-size:12px; 
margin-left:6px;
`;

const UploadIcon = () => {

    return (
        <Wrapper>

            <Icon src={uploadIcon} alt="Upload Icon" />
            <UploadText>Upload</UploadText>

        </Wrapper>
    );
};
export default UploadIcon;
